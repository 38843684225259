<template>
  <div class="homee">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">天</a>
      <a class="btn" @click="month(2)" :class="{active:dynamic==2}">月</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 年
      </a>
    </div>
    <div :style="{ width: '630px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        newdata: '',
        //传的参值
        dynamic: 3,
        allUserCount: '', //总用户人数
        courseCount: '', //小程序用户人数
        generalCount: '', //普通用户人数
        h5Count: '', //H5用户人数
        officialCount: '', //公众号用户人数
        shopUserCount: '', //商家用户人数
        staffUserCount: '', //员工用户人数
        vipCount: '', //会员用户人数
        workUserCount: '', //师傅用户人数
        time: '',
        newdingdan: '',
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);

        let xLabel = ["总用户人", "小程序", "普通", 'H5', "公众号", "商家", "员工", '会员', '师傅'];
        var option = {
          grid: {
            left: '1%',
            right: '2%',
            bottom: '10%',
            top: '25%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            formatter: function(params) {
              return params[0].name + ' 用户人数: ' + params[0].value+'人'
            }
          },
          xAxis: {
            data: xLabel,
            axisLine: {
              show: true, //隐藏X轴轴线
              lineStyle: {
                color: '#11417a'
              }
            },
            axisTick: {
              show: false //隐藏X轴刻度
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 14,
              textStyle: {
                color: "#A3C0DF" //X轴文字颜色
              }
            },

          },
          yAxis: [{
            type: "value",
            gridIndex: 0,
            // splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#113763',
                width: 1
              },
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#11417a'
              }
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 14,
              textStyle: {
                color: "#A3C0DF" //X轴文字颜色
              }
            },
          }, ],
          series: [{
              name: "用户人数",
              type: "bar",
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: "#07ecd9"
                    },
                    {
                      offset: 1,
                      color: "#034881"
                    }
                  ])
                }
              },
              data: this.newdata,
              z: 10,
              zlevel: 0,
              "label": {
                "show": true,
                "position": "top",
                "distance": 10,
                fontSize: 16,
                "color": "#01fff4"
              }
            },
            {
              // 分隔
              type: "pictorialBar",
              itemStyle: {
                normal: {
                  color: "#0F375F"
                }
              },
              symbolRepeat: "fixed",
              symbolMargin: 6,
              symbol: "rect",
              symbolClip: true,
              symbolSize: [20, 2],
              symbolPosition: "start",
              symbolOffset: [0, -1],
              // symbolBoundingData: this.total,
              data: this.newdata,
              width: 25,
              z: 0,
              zlevel: 1,
            },
          ]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('user/getUserStatistics?time=' + this.year + '&flag=' + 1, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, 'dadasdsa')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.allUserCount = res.data.data.allUserCount,
              this.courseCount = res.data.data.courseCount,
              this.generalCount = res.data.data.generalCount,
              this.h5Count = res.data.data.h5Count,
              this.officialCount = res.data.data.officialCount,
              this.shopUserCount = res.data.data.shopUserCount,
              this.staffUserCount = res.data.data.staffUserCount,
              this.vipCount = res.data.data.vipCount,
              this.workUserCount = res.data.data.workUserCount
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      month(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('user/getUserStatistics?time=' + this.year + '&flag=' + 2, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, 'dadasdsa')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.allUserCount = res.data.data.allUserCount,
              this.courseCount = res.data.data.courseCount,
              this.generalCount = res.data.data.generalCount,
              this.h5Count = res.data.data.h5Count,
              this.officialCount = res.data.data.officialCount,
              this.shopUserCount = res.data.data.shopUserCount,
              this.staffUserCount = res.data.data.staffUserCount,
              this.vipCount = res.data.data.vipCount,
              this.workUserCount = res.data.data.workUserCount
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      yearsday(e) {
        this.dynamic = e
        this.getselectOrdersToTal()
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('user/getUserStatistics?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, 'dadasdsa')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.allUserCount = res.data.data.allUserCount,
              this.courseCount = res.data.data.courseCount,
              this.generalCount = res.data.data.generalCount,
              this.h5Count = res.data.data.h5Count,
              this.officialCount = res.data.data.officialCount,
              this.shopUserCount = res.data.data.shopUserCount,
              this.staffUserCount = res.data.data.staffUserCount,
              this.vipCount = res.data.data.vipCount,
              this.workUserCount = res.data.data.workUserCount
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 13000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 531px;
    bottom: 295px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>