<template>
  <div class="people">
    <div :style="{ width: '500px', height: '350px' }" ref="myChart"
      style="position: absolute; left: -100px; top:20px; float: left;"></div>
    <div class="people_1">
      <div class="people_1_1">
        <div class="text">
          待接单
        </div>
        <div class="num">
          {{num1}}
        </div>
      </div>
      <div class="people_1_2">
        <div class="text">
          待服务
        </div>
        <div class="num">
          {{num2}}
        </div>
      </div>
      <div class="people_1_3">
        <div class="text">
          进行中
        </div>
        <div class="num">
          {{num3}}
        </div>
      </div>
      <div class="people_1_4">
        <div class="text">
          已完成
        </div>
        <div class="num">
          {{num4}}
        </div>
      </div>
      <div class="people_1_5">
        <div class="text">
          已取消
        </div>
        <div class="num">
          {{num5}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num3: 99,
        num1: 888,
        num2: 555,
        num4: 555,
        num5: '',
        uernum: '',
        Timer: ""
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        let angle = 0; //角度，用来做简单的动画效果的
        let value = this.uernum; //图上角度数据
        var option = {
          title: {
            text: '{a|' + value + '}{c|个}',
            x: 'center',
            y: 'center',
            textStyle: {
              rich: {
                a: {
                  fontSize: 45,
                  color: '#ffffff',
                  fontWeight: 'bold'
                },
                c: {
                  fontSize: 45,
                  color: '#ffffff',
                  fontWeight: 'normal'
                }
              }
            }
          },
          series: [
            //内环
            {
              name: "",
              type: 'custom',
              coordinateSystem: "none",
              renderItem: function(params, api) {
                return {
                  type: 'arc',
                  shape: {
                    cx: api.getWidth() / 2,
                    cy: api.getHeight() / 2,
                    r: Math.min(api.getWidth(), api.getHeight()) / 2.3 * 0.65,
                    startAngle: (0 + -angle) * Math.PI / 180,
                    endAngle: (360 + -angle) * Math.PI / 180
                  },
                  style: {
                    stroke: "#0CD3DB",
                    fill: "transparent",
                    lineWidth: 0.5
                  },
                  silent: true
                };
              },
              data: [0]
            },
            //外环
            {
              name: '',
              type: 'pie',
              radius: ['55%', '50%'],
              silent: true,
              clockwise: true,
              startAngle: 90,
              z: 0,
              zlevel: 0,
              label: {
                normal: {
                  position: "center",
                }
              },
              data: [{
                  value: value,
                  name: "",
                  itemStyle: {
                    normal: {
                      //外环发光
                      borderWidth: 0.5,
                      shadowBlur: 20,
                      borderColor: '#4bf3f9',
                      shadowColor: '#9bfeff',
                      color: { // 圆环的颜色
                        colorStops: [{
                          offset: 0,
                          color: '#4bf3f9', // 0% 处的颜色
                        }, {
                          offset: 1,
                          color: '#4bf3f9', // 100% 处的颜色
                        }]
                      },
                    }
                  }
                },
                {
                  value: 100 - value,
                  name: "",
                  label: {
                    normal: {
                      show: false
                    }
                  },
                  itemStyle: {
                    normal: {
                      color: "#173164"
                    }
                  }
                }
              ]
            },
          ]
        };

        var app = {
          currentIndex: -1,
        };
        setInterval(function() {
          var dataLen = option.series[0].data.length;

          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
          app.currentIndex = (app.currentIndex + 1) % dataLen;
          window.onresize = function() {
            clearInterval(() => {
              app.currentIndex
            }, 19500)
          }
          //console.log(app.currentIndex);
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      //ttps://tcwmadmin.xianmaxiong.com/sqx_fast/
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/help/selectStatusHelpOrder?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 0 +
          '&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          // console.log(res, '总订单')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            this.uernum = res.data.data.totalCount
            this.getEchartData()
          }

        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+1+'&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          // console.log(res, '待支付')
          if (res.data.code == 401) {
            this.$router.push('/')

          } else {
            this.num1 = res.data.data.totalCount
            this.getEchartData()
          }

        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+2+'&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          //console.log(res, '商家待接单')
          if (res.data.code == 401) {
            this.$router.push('/')

          } else {
            this.num2 = res.data.data.totalCount
            this.getEchartData()
          }

        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+3+'&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')

          } else {
            this.num3 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+4+'&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }
        
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
        
          } else {
            this.num4 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+5+'&content=&gameId=&taskType', {
            headers: {
              'token': Authorization
            }
        
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
        
          } else {
            this.num5 = res.data.data.totalCount
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 5000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .people {
    width: 100%;
    height: 100%;
  }

  .people_1 {
    width: 245px;
    height: 248px;
    float: right;
    margin: 64px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    

    .people_1_1 {
      width: 100%;
      height: 20%;
      margin: 10px 0;
      background-image: url('../../assets/img/20-icon-1.png');
      background-repeat: no-repeat;
      background-size: 30% 100%;
    }

    .people_1_2 {
      width: 100%;
      height: 20%;
      margin: 10px 0;
      background-image: url('../../assets/img/21-icon-2.png');
      background-repeat: no-repeat;
      background-size: 30% 100%;
    }

    .people_1_3 {
      width: 100%;
      height: 20%;
      background-image: url('../../assets/img/22-icon-3.png');
      background-repeat: no-repeat;
      background-size: 30% 100%;
    }

    .people_1_4 {
      width: 100%;
      height: 20%;
      background-image: url('../../assets/img/22-icon-3.png');
      background-repeat: no-repeat;
      background-size: 30% 100%;
    }

    .people_1_5 {
      width: 100%;
      height: 20%;
      background-image: url('../../assets/img/22-icon-3.png');
      background-repeat: no-repeat;
      background-size: 30% 100%;
    }
  }

  .text {
    width: 150px;
    color: #fff;
    font-size: 20px;
    margin: 1px 0 0 100px;
  }

  .num {
    width: 50px;
    color: gold;
    font-size: 20px;
    margin: -25px 0 0 172px;
  }
</style>