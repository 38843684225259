<template>
  <div class="homee">
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        time: '',
        year: '',
        day: '',
        dynamic: 3,
        Timer: ""
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          tooltip: {
            trigger: 'axis',
            padding: [5, 10],
            textStyle: {
              color: '#7588E4',
            },
            extraCssText: 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'
          },
          grid: {
            top: '22%',
            right: '10%',
            left: '10%',
            bottom: '15%'
          },
          xAxis: {
            type: 'category',
            data: [
              '待服务',
              '进行中',
              '已完成',
              '已取消'
            ],
            boundaryGap: false,
            splitLine: {
              show: true,
              interval: 'auto',
              lineStyle: {
                color: ['#D4DFF5']
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#609ee9'
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14
              }
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                color: ['#D4DFF5']
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#609ee9'
              }
            },
            axisLabel: {
              margin: 10,
              textStyle: {
                fontSize: 14
              }
            }
          },
          series: [{
            name: '订单',
            type: 'line',
            smooth: true,
            showSymbol: false,
            symbol: 'circle',
            symbolSize: 6,
            data: [this.num1,this.num2,this.num3,this.num4],
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(216, 244, 247,1)'
                }, {
                  offset: 1,
                  color: 'rgba(216, 244, 247,1)'
                }], false)
              }
            },
            itemStyle: {
              normal: {
                color: '#58c8da'
              }
            },
            lineStyle: {
              normal: {
                width: 3
              }
            }
          }]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //help/selectHelpTakeList?page=1&limit=10&phone=&status=1&gameId=&startTime=&endTime=&taskType=
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 1 +
          '&gameId=&startTime=&endTime=&taskType=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num1 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 2 +
          '&gameId=&startTime=&endTime=&taskType=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num2 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 3 +
          '&gameId=&startTime=&endTime=&taskType=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num3 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 4 +
          '&gameId=&startTime=&endTime=&taskType=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num4 = res.data.data.totalCount
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 9000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }

  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 396px;
    bottom: 269px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>