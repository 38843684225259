<template>
  <div class="homee">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">天</a>
      <a class="btn" @click="month(2)" :class="{active:dynamic==2}">月</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 年
      </a>
    </div>
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //传的参值
        dynamic: 3,
        serviceDaiFuWuCount: 16, //待服务(服务订单)
        serviceJinXingCount: 1, //进行中(服务订单)
        serviceMoney: 2677, //服务订单总金额
        serviceTuiKuanCount: 21, //已退款(服务订单)
        serviceWanChengCount: 11, //已完成(服务订单)
        time: '',
        newdingdan: '',
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          color: ["#3cefff"],
          tooltip: {
            formatter(a) {
              return a.name + ':' + a.data
            }
          },
          grid: {
            left: '10%',
            right: '2%',
            bottom: '10%',
            top: '20%',
            containLabel: true
          },
          xAxis: [{
            type: "category",
            data: ['待服务(笔)', '进行中(笔)', '总金额(元)', '已退款(笔)', '已完成(笔)'],
            axisTick: {
              alignWithLabel: true
            },
            nameTextStyle: {
              color: "#82b0ec"
            },
            axisLine: {
              lineStyle: {
                color: "#82b0ec"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#82b0ec"
              }
            }
          }],
          yAxis: [{
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#82b0ec"
              },
              formatter: "{value}"
            },
            splitLine: {
              lineStyle: {
                color: "#0c2c5a"
              }
            },
            axisLine: {
              show: false
            }
          }],
          series: [{
              name: "",
              type: "pictorialBar",
              symbolSize: [20, 10],
              symbolOffset: [0, -5],
              symbolPosition: "end",
              z: 12,
              label: {
                normal: {
                  show: true,
                  position: "top",
                  formatter: "{c}"
                }
              },
              data: this.newdata
            }, {
              name: "",
              type: "pictorialBar",
              symbolSize: [20, 10],
              symbolOffset: [0, 5],
              z: 12,
              data: this.newdata
            }, {
              type: "bar",
              itemStyle: {
                normal: {
                  opacity: 0.7
                }
              },
              barWidth: "20",
              data: this.newdata,
              markLine: {
                silent: true,
                symbol: "none",
                label: {
                  position: "middle",
                  formatter: "{b}"
                },

              }
            },
            {
              type: 'effectScatter',
              silent: true,
              tooltip: {
                show: false
              },
              zlevel: 3,
              symbolSize: [20, 10],
              // symbolOffset: [0, 0],
              symbolPosition: 'end',
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                color: '#3cefff',
                scale: 4
              },
              itemStyle: {
                color: '#3cefff'
              },
              hoverAnimation: true,
              data: [0, 0, 0, 0, 0, 0, 0, 0]
            },
          ]
        }
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 1, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'sadasdas')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
              this.serviceJinXingCount = res.data.data.serviceJinXingCount,
              this.serviceMoney = res.data.data.serviceMoney,
              this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
              this.serviceWanChengCount = res.data.data.serviceWanChengCount,
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      month(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 2, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'sadasdas')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
              this.serviceJinXingCount = res.data.data.serviceJinXingCount,
              this.serviceMoney = res.data.data.serviceMoney,
              this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
              this.serviceWanChengCount = res.data.data.serviceWanChengCount,
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      yearsday(e) {
        this.dynamic = e
        this.getselectOrdersToTal()
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'sadasdas')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
              this.serviceJinXingCount = res.data.data.serviceJinXingCount,
              this.serviceMoney = res.data.data.serviceMoney,
              this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
              this.serviceWanChengCount = res.data.data.serviceWanChengCount,
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 13000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    right: 64px;
    bottom: 604px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>