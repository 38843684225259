<template>
  <div class="contonr">
    <span class="text">接单列表</span>
    <!-- 地图球行 -->
    <div class='map'>
      <people5></people5>
      <div class="map1"></div>
      <div class="map2" :class="{'rotate360':showAnimate}"></div>
      <div class="map3" :class="{'rotate1':showAnimatd}"></div>
    </div>
  </div>
</template>

<script>
  import people5 from "./people5.vue"
  export default {
    components: {
      people5
    },
    data() {
      return {
        num1: 18796,
        num2: 11908,
        showAnimate: false,
        showAnimatd: false,
        xianshi: true,
        xianshione: false,
        Timer: '',
      }
    },
    mounted() {
      this.showAnimate = true
      this.showAnimatd = true
    },
    created() {
      this.getDate()
    },
    methods: {
      chengzhen() {
        this.xianshione = true;
        this.xianshi = false;
      },
      feixing() {
        this.xianshione = false;
        this.xianshi = true;
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },
    },
  }
</script>

<style scoped lang="less">
  .contonr {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;

    .text {
      margin: -4px 344px;
      color: #fff;
      text-align: center;
      font-size: 32px;
      font-weight: 800;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .topp {
      width: 100%;
      height: 20%;
      background-image: url('../../assets/images/line(1).png');
      background-size: 100% 100%;
      border: 1px #ccc dashed;
      margin: 20px 0 0 0;

    }

    .map {
      width: 100%;
      height: 70%;
    }

  }



  .line {
    height: 190px;
    position: absolute;
    top: 23px;
    left: 50%;
    border: 1px rgb(0, 149, 255, 0.2) solid;
  }

  .no {
    width: 96%;
    height: 200px;
    display: flex;
    text-align: center;
  }

  .no-hd {
    width: 50%;
    height: 100%;
  }

  .no-bd {
    width: 50%;
    height: 100%;
  }

  .num {
    margin-top: 30px;
    color: #ffeb7b;
    padding: 10px 0;
    font-family: "electronicFont";
    font-weight: bold;
    font-size: 20px;
  }

  .textone {
    position: absolute;
    left: 101px;
    top: 106px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 40px;
    color: white;
  }

  .texttwo {
    position: absolute;
    left: 522px;
    top: 106px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 40px;
    color: white;
  }



  .map1 {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../assets/images/map.png');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: .3;
  }

  .map2 {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../assets//images/lbx.png');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 1;
  }

  .map3 {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../assets/images/jt.png');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: .6;
  }

  .rotate360 {
    animation: rotate360 10s linear infinite;
  }

  .rotate1 {
    animation: rotate1 10s linear infinite;
  }

  @keyframes rotate360 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes rotate1 {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
</style>