<template>
  <div class="shuju">
    <div :style="{ width: '280px', height: '300px' }" ref="myChart" style="position: absolute; top:450px"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        allCount: '',//总提现次数
        allMoney: '',//累计提现总金额
        refuseCount: '',//已驳回次数
        refuseMoney: '',//已驳回金额
        traverseCount: '',//已提现次数
        traverseMoney: '',//已提现金额
        waitCount: '',//待转账次数
        waitMoney: '',//待转账金额
        wxAllCount: '',//微信转账总次数
        wxAllMoney: '',//微信提现总金额
        wxRefuseCount: '',//微信已驳回次数
        wxRefuseMoney: '',//微信已驳回金额
        wxTraverseCount: '',//微信已转账次数
        wxTraverseMoney: '',//微信已提现金额
        wxWaitCount: '',//微信待转账次数
        wxWaitMoney: '',//微信待提现金额
        zfbAllCount: '',//支付宝提现总金额
        zfbAllMoney: '',//支付宝提现总金额
        zfbRefuseCount: '',//支付宝已驳回次数
        zfbRefuseMoney: '',//支付宝已驳回金额
        zfbTraverseCount: '',//支付宝已提现次数
        zfbTraverseMoney: '',//支付宝已提现金额
        zfbWaitCount: '',//支付宝待提现次数
        zfbWaitMoney: '',//支付宝待提现金额
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var aqiData =  this.allMoney;
        var option = {
          series: [{
            type: 'liquidFill',
            animation: true,
            waveAnimation: true,
            data: [0.75, 0.70],
            color: [{
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                  offset: 0,
                  color: '#446bf5',
                },
                {
                  offset: 1,
                  color: '#2ca3e2',
                },
              ],
              globalCoord: false,
            }, ], //波浪颜色
            backgroundStyle: {
              borderWidth: 1, //外边框
              // borderColor: '#23cc72', //边框颜色
              color: 'RGBA(51, 66, 127, 0.7)', //边框内部填充部分颜色
            },
            amplitude: 8,
            radius: '80%',
            label: {
              normal: {
                formatter: function() {
                  return aqiData + '元';
                },
                textStyle: {
                  fontSize: 52,
                  color: '#fff',
                  fontWeight: "normal"
                },
                position: ['50%', '50%']
              }
            },
            outline: {
              itemStyle: {
                borderColor: '#aaa4a4',
                borderWidth: 1,
                color: 'none',
                shadowBlur: 0,
                shadowColor: "#fff"
              },
              borderDistance: 0
            },
          }]
        };
        myChart.clear();
        myChart.setOption(option, true);
      },

      getstatisticsMoney() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/statisticsCashMoney?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, '累计提现总金额')
          if (res.data.code == 401) {
            this.$router.push('/')
          } else {
            this.allMoney=res.data.data.allMoney
          }
          this.getEchartData()
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getstatisticsMoney()
      this.Timer = setInterval(this.getstatisticsMoney, 2000)
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .shuju {
    width: 100%;
    height: 100%;
  }
</style>