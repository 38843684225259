<template>
  <div class="concent">
    <div class="wenbennum1">
      <div class="text">
        充值总金额
      </div>
      <div class="nun">
        {{allMoney}}元
      </div>
    </div>
    <div class="wenbennum2">
      <div class="text">
        微信公众号充值金额
      </div>
      <div class="nun">
        {{wxOfficial}}元
      </div>
    </div>
    <div class="wenbennum3">
      <div class="text">
        微信小程序充值金额
      </div>
      <div class="nun">
        {{wxCourse}}元
      </div>
    </div>
    <div class="wenbennum4">
      <div class="text">
        微信app充值金额
      </div>
      <div class="nun">
        {{wxApp}}元
      </div>
    </div>
    <div class="wenbennum5">
      <div class="text">
        支付宝App充值金额
      </div>
      <div class="nun">
        {{zfbApp}}元
      </div>
    </div>
    <div class="wenbennum6">
      <div class="text">
        支付宝H5充值金额
      </div>
      <div class="nun">
        {{zfbH5}}元
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        allMoney: "", //充值总金额
        wxApp: "", //微信app充值金额
        wxCourse: "", //微信小程序充值金额
        wxOfficial: "", //微信公众号充值金额
        zfbApp: "", //支付宝App充值金额
        zfbH5: "", //支付宝H5充值金额
        Timer: ''
      }
    },
    methods: {
      getcaiwuzhongxin() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //收入统计
        this.$axios.get('/cash/payMember?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          console.log(res, '财务中心收入统计')
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.allMoney=res.data.data.allMoney
            this.wxApp=res.data.data.wxApp
            this.wxCourse=res.data.data.wxCourse
            this.wxOfficial=res.data.data.wxOfficial
            this.zfbApp=res.data.data.zfbApp
            this.zfbH5=res.data.data.zfbH5
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },
    },
    mounted() {
      this.getcaiwuzhongxin()
      this.Timer = setInterval(this.getcaiwuzhongxin, 20000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style lang="less" scoped>
  .concent {
    width: 100%;
    height: 70%;
    margin-top: 52px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    .wenbennum1 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

    }

    .wenbennum2 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .wenbennum3 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .wenbennum4 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .wenbennum5 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .wenbennum6 {
      width: 33.3%;
      height: 80px;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .text {
    width: 253px;
    margin-top: 7px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: #fff;
    font-size: 28px;
  }

  .nun {
    width: 253px;
    font-weight: 800;
    text-align: center;
    color: goldenrod;
    font-size: 28px;
  }
</style>