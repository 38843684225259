<template>
  <div class="cpjs">
    <div class="cpjs1">
      <div class="text">
        已驳回次数
      </div>
      <div class="num">
        {{refuseCount}}
      </div>
    </div>
    <div class="cpjs2">
      <div class="text">
        已驳回金额
      </div>
      <div class="num">
        {{refuseMoney}}
      </div>
    </div>
    <div class="cpjs3">
      <div class="text">
        已提现次数
      </div>
      <div class="num">
        {{traverseCount}}
      </div>
    </div>
    <div class="cpjs4">
      <div class="text">
        待转账次数
      </div>
      <div class="num">
        {{waitCount}}
      </div>
    </div>
    <div class="cpjs5">
      <div class="text">
        待转账金额
      </div>
      <div class="num">
        {{waitMoney}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        refuseCount: '', //已驳回次数
        refuseMoney: '', //已驳回金额
        traverseCount: '', //已提现次数
        waitCount: '', //待转账次数
        waitMoney: '', //待转账金额
        Timer: ''
      }
    },
    methods: {
      getRecharge() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/statisticsCashMoney?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push('/')
          } else {
            this.refuseCount = res.data.data.refuseCount
            this.refuseMoney = res.data.data.refuseMoney
            this.traverseCount = res.data.data.traverseCount
            this.waitCount = res.data.data.waitCount
            this.waitMoney = res.data.data.waitMoney
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },
    },
    mounted() {
      this.getRecharge()
      this.Timer = setInterval(this.getRecharge, 2000)
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .cpjs {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 37px;

    .cpjs1 {
      width: 20%;
      height: 100%;
      background-image: url('../../assets/img/23-hxdc.png');
      background-repeat: no-repeat;
      background-size: 100% 70%;
    }

    .cpjs2 {
      width: 20%;
      height: 100%;
      background-image: url('../../assets/img/23-hxdc.png');
      background-repeat: no-repeat;
      background-size: 100% 70%;
    }

    .cpjs3 {
      width: 20%;
      height: 100%;
      background-image: url('../../assets/img/23-hxdc.png');
      background-repeat: no-repeat;
      background-size: 100% 70%;
    }

    .cpjs4 {
      width: 20%;
      height: 100%;
      background-image: url('../../assets/img/23-hxdc.png');
      background-repeat: no-repeat;
      background-size: 100% 70%;
    }

    .cpjs5 {
      width: 20%;
      height: 100%;
      background-image: url('../../assets/img/23-hxdc.png');
      background-repeat: no-repeat;
      background-size: 100% 70%;
    }
  }

  .text {
    width: 200px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    margin: 132px 0 0 -4px;
  }

  .num {
    width: 200px;
    text-align: center;
    color: goldenrod;
    font-size: 20px;
    font-weight: 900;
    margin: 19px 0 0 -4px;
  }
</style>