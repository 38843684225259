<template>
  <div class="homeee">
    <div class="home1">
      <div class="text">
        佣金总金额
      </div>
      <div class="num">
        {{num1}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home2">
      <div class="text">
        推广员金额
      </div>
      <div class="num">
        {{num2}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home3">
      <div class="text">
        平台金额
      </div>
      <div class="num">
        {{num3}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home4">
      <div class="text1">
        商家金额
      </div>
      <div class="num1">
        {{num4}}
      </div>
      <div class="unit1">
        元
      </div>
    </div>
    <div class="home5">
      <div class="text1">
        师傅金额
      </div>
      <div class="num1">
        {{num5}}
      </div>
      <div class="unit1">
        元
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num1: 999,
        num2: '222',
        num3: '323',
        num4: '32432',
        num5: '324',
        Timer: '324'
      }
    },
    methods: {
      getselectShopCenter() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //骑手用户
        this.$axios.get('cash/commissionCensus?time='+this.time+'&flag='+3, {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          console.log(res, '骑手用户')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let ress=res.data.data
            this.num1=ress.allMoney
            this.num2=ress.popularizeMoney
            this.num3=ress.pingMoney
            this.num4=ress.shopMoney
            this.num5=ress.workerMoney
          }

        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
           if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates 
      },

    },
    mounted() {
      this.getselectShopCenter()
      this.Timer = setInterval(this.getselectShopCenter, 20000)
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .homeee {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .home1 {
      width: 33.3%;
      height: 200px;
      background-image: url('../../assets/img/12-zbk02.png');
      background-repeat: no-repeat;
      background-size: 65% 80%;
      transform: translate(18%);
    }

    .home2 {
      width: 33.3%;
      height: 200px;
      background-image: url('../../assets/img/12-zbk02.png');
      background-repeat: no-repeat;
      background-size: 65% 80%;
       transform: translate(19%);
    }

    .home3 {
      width: 33.3%;
      height: 200px;
      background-image: url('../../assets/img/12-zbk02.png');
      background-repeat: no-repeat;
      background-size: 65% 80%;
       transform: translate(17%);
    }

    .home4 {
      width: 50%;
      height: 200px;
      background-image: url('../../assets/img/12-zbk02.png');
      background-repeat: no-repeat;
      background-size: 48% 80%;
       transform: translate(45%);
    }

    .home5 {
      width: 50%;
      height: 200px;
      background-image: url('../../assets/img/12-zbk02.png');
      background-repeat: no-repeat;
      background-size: 48% 80%;
       transform: translate(15%);
    }
  }
  .text{
    width: 63%;
    text-align: center;
    color:#fff;
    font-size: 25px;
  }
  .num{
    width: 63%;
    text-align: center;
    color:gold;
    font-size: 23px;
    margin: 13px 0;
  }
  .unit{
    width: 63%;
    text-align: center;
    color:#fff;
    font-size: 22px;
  }
  .text1{
    width: 47%;
    text-align: center;
    color:#fff;
    font-size: 25px;
  }
  .num1{
    width: 47%;
    text-align: center;
    color:gold;
    font-size: 23px;
    margin: 13px 0;
  }
  .unit1{
    width: 47%;
    text-align: center;
    color:#fff;
    font-size: 22px;
  }
</style>