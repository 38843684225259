<template>
  <div id="app">
    <div class="table_body">
      <div class="table_th">
        <div class="tr1 th_style">编号</div>
        <div class="tr2 th_style">内容</div>
        <div class="tr3 th_style">投诉类型</div>
        <div class="tr4 th_style">状态</div>
      </div>
      <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
        <div class="table_tr" v-for="(item,index) in listData" :key="index">
          <div class="tr1 tr">{{item.bianhao}}</div>
          <div class="tr2 tr">{{item.title}}</div>
          <div class="tr3 tr">
            <div v-if="item.date==1">聊天投诉</div>
            <div v-if="item.date==2">用户投诉</div>
            <div v-if="item.date==3">任务投诉</div>
            <div v-if="item.date==4">服务投诉</div>
          </div>
          <div class="tr4 tr">
            <div v-if="item.name==0" style="color: greenyellow;">待处理</div>
            <div v-if="item.name==1" style="color: yellow;">已驳回</div>
            <div v-if="item.name==2" style="color: red;">已封号</div>
            <div v-if="item.name==3" style="color: fuchsia;">已处理</div>
            <div v-if="item.name==4" style="color: skyblue;">不处理</div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>

  </div>

</template>


<script>
  export default {
    data() {
      return {
        userNum: '',
        listData: []
      }
    },
    computed: {
      optionHover() {
        return {
          step: 0.5, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 100, // 单步运动停止的时间(默认值1000ms)

        };
      },

    },
    methods: {
      getuser() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/message/selectMessageByType?page=' + 1 + '&limit=' + 10 + '10&state=' + 3 + '&platform=', {
          headers: {
            'token': Authorization
          }
        }).then((res) => {

          let ress = res.data.data.list

          this.userNum = ress
          var arrNew = []
          arrNew = this.userNum.map((item) => {
            return {
              bianhao: item.id, //状态
              name: item.status, //下单用户
              title: item.content, //技师用户
              date: item.platform //价格
            }
          })
          this.listData = arrNew
          console.log(this.listData, '获取推广')
        })
      }
    },
    created() {
      this.getuser()
    }
  }
</script>
<style lang="less" scoped>
  .table_th {
    width: 100%;
    display: flex;
    height: 78px;
    line-height: 120px;
  }

  .tr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 0.1125rem;
    text-align: center;
    font-size: 20px;
  }

  .tr1 {
    width: 28%;
  }

  .tr2 {
    width: 19%;
  }

  .tr3 {
    width: 35%;
  }

  .tr4 {
    flex: 1;
  }

  .th_style {
    color: rgb(0, 221, 253);
    font-weight: bold;
    font-size: 21px;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
  }


  .seamless-warp {
    height: 400px;
    overflow: hidden;

    ul li {
      list-style-type: none;
    }
  }

  .table_tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #eee;
    font-size: 0.9375rem;
    background: rgba(3, 145, 167, 0.1);
    border: 0.5px solid rgb(4, 114, 131);
    border-right: none;
    border-left: none;
    margin-top: 0.375rem;
  }

  .el-table .cell {
    text-align: center;
  }

  .toptitle {
    width: 100%;
    display: flex;
    background-color: gainsboro;
    margin-bottom: 0.625rem;
    border: 0.125rem solid gainsboro;
    background-color: transparent;
    color: white
  }

  .item {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    line-height: 50px;
    height: 50px;
    color: #eee;
    background: rgba(3, 145, 167, 0.1);
    border: 1rem solid rgb(4, 114, 131);
    margin-top: 0.4375rem;

    .date {
      width: 33%;
      position: absolute;
      left: -0.2rem;
      color: aqua;
    }

  }
</style>