<template>
    <v-scale-screen width="1920" height="1080" :fullScreen="true" >
      <div id="app"> 
       <Title></Title>
      
        <router-view /> 
      </div>
   	</v-scale-screen>
  
</template>
<script>
  import { defineComponent } from "vue"
  import VScaleScreen from 'v-scale-screen'
  import Title from "../views/title.vue"
  export default{
    components:{
      Title,VScaleScreen
    },
    data() {
      return{
      }
    },
    methods:{
     
    },
    mounted() {
     
    }
  }
  
</script>
<style lang="less" scoped>
  body{
    overflow: hidden;
  }
 #app{
   width: 100%;
   height: 100%;
   background-image: url('../assets/img/01-bj.png');
   background-repeat: no-repeat;
   background-size: 100% 100%;
 }
 
</style>
