import Vue from "vue";
import VueRouter from "vue-router";
import SJ from "../components/shujunum/shujunum.vue"
import PEOPLE from "../components/people/people.vue"
import chanpin from "../components/chanpingjieshao/chanpingjieshao.vue"
import loginName from '@/pages/login.vue';
import homeName from '@/pages/home.vue'
import Homee from "../components/homee/homee.vue"
// import homeName from "../App.vue"
Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'loginName',
    component: loginName,
    meta: {
      needLogin: true //需要加校检判断的路由
    },
  },
  {
    path: '/home',
    name: 'homeName',
    component: homeName,
    children: [{
        path: "/Homee",
        name: "Homee",
        component: Homee,
      }, {
        path: "/SJ",
        name: "SJ",
        component: SJ,
      },
      {
        path: "/PEOPLE",
        name: "PEOPLE",
        component: PEOPLE,
      },
      {
        path: "/chanpin",
        name: "chanpin",
        component: chanpin,
      }
    ]
  },
];

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next();
  } else {
    let token = window.sessionStorage.getItem("Authorization");
    if (token === null || token === '') {
      next({
        name: 'loginName'
      });
    } else {
      next();
    }
  }
});

export default router;
