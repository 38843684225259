<template>
  <div class="homee">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">天</a>
      <a class="btn" @click="month(2)" :class="{active:dynamic==2}">月</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 年
      </a>
    </div>
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        HelpOrderDaiJieDanCount: 5, //待接单(万能任务)
        HelpOrderDaiShenHeCount: 16, //待审核(万能任务)
        HelpOrderDaiSongDaCount: 4, //待送达(万能任务)
        HelpOrderMoney: 60753, //万能任务总金额
        HelpOrderYiQuXiaoCount: 7, //已取消(万能任务)
        HelpOrderYiWanChengCount: 13, //已完成(万能任务)
        billCount: '', //发单数量
        billMoney: '', //接单数量
        receivingMoney: '', //接单总金额
        reveivingCount: '', //发单总金额
        dynamic: 3,
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          grid: {
            top: "28%",
            bottom: "20%",
            left: '20%',
            right:'1%'
          },
          legend: {
            top: 70,
            itemGap: 15,
            data: ['万能任务'],
            textStyle: {
              color: '#fff',
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            padding: 10,
          },
          xAxis: {
            nameTextStyle: {
              color: '#fff',
              fontSize: 14,
            },
            axisLabel: {
              margin: 20,
              color: '#fff',
              fontSize: 14,
            },
            type: 'category',
            nameLocation: 'center',
            nameGap: 50,
            axisLine: {
              lineStyle: {
                type: 'dashed',
                color: '#ECECEC',
              },
            },
            data: [ "待接单(笔)", "待审核(笔)", '待送达(笔)', "总金额(元)", "已取消(笔)", "已完成(笔)"],
          },
          yAxis: [{
              name: '',
              nameTextStyle: {
                color: '#fff',
                fontSize: 14,
              },
              axisLabel: {
                margin: 20,
                color: '#fff',
                fontSize: 14,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#ECECEC',
                },
              },
              axisLine: {
                show: false,
              },
              type: 'value',
              minInterval: 1,
              nameGap: 20,
            },
            {
              nameTextStyle: {
                color: '#fff',
                fontSize: 14,
              },
              axisLabel: {
                margin: 20,
                color: '#fff',
                fontSize: 14,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#ECECEC',
                },
              },
              axisLine: {
                show: false,
              },
              type: 'value',
              minInterval: 1,
              nameGap: 20,
            },
          ],
          series: [{
              type: 'line',
              name: '万能任务',
              yAxisIndex: 0,
              symbol: 'emptyCircle',
              smooth: 0.3,
              symbolSize: 8,
              itemStyle: {
                borderWidth: 2,
                color: '#17A2B8',
              },
              emphasis: {
                itemStyle: {
                  color: '#17A2B8',
                  borderColor: '#fff',
                },
              },
              data:this.newdata
            },

          ],
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > 6) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 1, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            let newdata = [
              this.HelpOrderDaiJieDanCount = res.data.data.HelpOrderDaiJieDanCount,
              this.HelpOrderDaiShenHeCount = res.data.data.HelpOrderDaiShenHeCount,
              this.HelpOrderDaiSongDaCount = res.data.data.HelpOrderDaiSongDaCount,
              this.HelpOrderMoney = res.data.data.HelpOrderMoney,
              this.HelpOrderYiQuXiaoCount = res.data.data.HelpOrderYiQuXiaoCount,
              this.HelpOrderYiWanChengCount = res.data.data.HelpOrderYiWanChengCount

            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      month(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 2, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {

          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
            
              this.HelpOrderDaiJieDanCount = res.data.data.HelpOrderDaiJieDanCount,
              this.HelpOrderDaiShenHeCount = res.data.data.HelpOrderDaiShenHeCount,
              this.HelpOrderDaiSongDaCount = res.data.data.HelpOrderDaiSongDaCount,
              this.HelpOrderMoney = res.data.data.HelpOrderMoney,
              this.HelpOrderYiQuXiaoCount = res.data.data.HelpOrderYiQuXiaoCount,
              this.HelpOrderYiWanChengCount = res.data.data.HelpOrderYiWanChengCount

            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      yearsday(e) {
        this.dynamic = e
        this.getselectOrdersToTal()
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'dadasdsa')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            let newdata = [
              this.HelpOrderDaiJieDanCount = res.data.data.HelpOrderDaiJieDanCount,
              this.HelpOrderDaiShenHeCount = res.data.data.HelpOrderDaiShenHeCount,
              this.HelpOrderDaiSongDaCount = res.data.data.HelpOrderDaiSongDaCount,
              this.HelpOrderMoney = res.data.data.HelpOrderMoney,
              this.HelpOrderYiQuXiaoCount = res.data.data.HelpOrderYiQuXiaoCount,
              this.HelpOrderYiWanChengCount = res.data.data.HelpOrderYiWanChengCount

            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 13000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    right: 83px;
    bottom: 291px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>