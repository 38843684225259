<template>
  <div class="concent">
    <router-link v-for="(item, index) in navArr" :key="index" class="router" active-class="active" :to="item.path">
      {{ item.content }}
    </router-link>
  </div>


</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        // 导航按钮数组
        navArr: [{
            path: "/Homee",
            content: "数据中心"
          }, {
            path: "/SJ",
            content: "用户中心"
          },
          {
            path: "/PEOPLE",
            content: "派单中心"
          },
          {
            path: "/chanpin",
            content: "财务中心"
          },

        ],
        dynamic: 0,
      };
    },
  };
</script>

<style scoped lang="less">
  .router-link-active {
    text-decoration: none;
  }

  a {
    text-decoration: none;
    color: white;
  }

  .active {
    background-image: url('../../assets/img/07-xz.png');
    background-position: right -10px bottom 10%;
    background-size: 120% 100%;
  }

  .concent {
    width: 624px;
    height: 90px;
    position: relative;
    left: 807px;
    z-index: 9999;

    .router:nth-child(1) {
      width: 20%;
      height: 61px;
      position: absolute;
      left: -586px;
      top: 50px;
      font-size: 25px;
      font-weight: 800;
      color: white;
      text-align: center;
    }

    .router:nth-child(2) {
      width: 20%;
      position: absolute;
      left: -372px;
      font-size: 25px;
      font-weight: 800;
      color: white;
      height: 61px;
      top: 50px;
      text-align: center;
    }

    .router:nth-child(3) {
      width: 20%;
      position: absolute;
      left: 565px;
      font-size: 25px;
      font-weight: 800;
      color: white;
      height: 61px;
      top: 50px;
      text-align: center;
    }

    .router:nth-child(4) {
      width: 20%;
      position: absolute;
      left: 778px;
      font-size: 25px;
      font-weight: 800;
      color: white;
      height: 61px;
      top: 50px;
      text-align: center;
    }
  }
</style>
