<template>
  <div class="homee">
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        time: '',
        year: '',
        day: '',
        dynamic: 3,
        Timer: ""
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          tooltip: {
            trigger: 'item',
            　position: 'top',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '30%',
            right: '3%',
            left: '15%',
            bottom: '18%'
          },
          xAxis: [{
            type: 'category',
            data: ['悬赏任务', '报价任务', '一口价任务'],
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.12)'
              }
            },
            axisLabel: {
              margin: 10,
              color: '#e2e9ff',
              textStyle: {
                fontSize: 14
              },
            },
          }],
          yAxis: [{
            name: '单',
            axisLabel: {
              formatter: '{value}',
              color: '#e2e9ff',
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255,255,255,1)'
              }
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.12)'
              }
            }
          }],
          series: [{
            type: 'bar',
            data: [this.num1,this.num2,this.num3],
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0,244,255,1)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(0,77,167,1)' // 100% 处的颜色
                }], false),
                barBorderRadius: [30, 30, 30, 30],
                shadowColor: 'rgba(0,160,221,1)',
                shadowBlur: 4,
              }
            },
            label: {
              normal: {
                show: true,
                lineHeight: 30,
                width: 80,
                height: 30,
                backgroundColor: 'rgba(0,160,221,0.1)',
                borderRadius: 200,
                position: ['-8', '-60'],
                distance: 1,
                formatter: [
                  '    {d|●}',
                  ' {a|{c}}     \n',
                  '    {b|}'
                ].join(','),
                rich: {
                  d: {
                    color: '#3CDDCF',
                  },
                  a: {
                    color: '#fff',
                    align: 'center',
                  },
                  b: {
                    width: 1,
                    height: 30,
                    borderWidth: 1,
                    borderColor: '#234e6c',
                    align: 'left'
                  },
                }
              }
            }
          }]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
             dataIndex: currentIndex
        
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
             dataIndex: currentIndex
        
          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+0+'&content=&gameId=&taskType='+1, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");  
          } else {
           this.num1=res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+0+'&content=&gameId=&taskType='+2, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");  
          } else {
           this.num2=res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectStatusHelpOrder?page='+1+'&limit='+10+'&phone=&status='+0+'&content=&gameId=&taskType='+3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");  
          } else {
           this.num3=res.data.data.totalCount
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 9000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }

  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 396px;
    bottom: 269px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>
