<template>
  <div class="homee">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">天</a>
      <a class="btn" @click="month(2)" :class="{active:dynamic==2}">月</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 年
      </a>
    </div>
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        serviceDaiFuWuCount: 16, //待服务(服务订单)
        serviceJinXingCount: 1, //进行中(服务订单)
        serviceMoney: 2677, //服务订单总金额
        serviceTuiKuanCount: 21, //已退款(服务订单)
        serviceWanChengCount: 11, //已完成(服务订单)
        servicehelperOrderAllCount: '', //服务订单总数量
        //传的参值
        dynamic: 3,
        Timer: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var xData = ['待服务(笔)', '进行中(笔)','总金额(元)','已退款(笔)','已完成(笔)','总数量(笔)'];
        var yData =  this.newdata;
        var option = {
          grid: {
            top: '30%',
            left: '0%',
            bottom: '13%',
            right: '0%',
            containLabel: true,
          },
          tooltip: {
            show: true,
          },
          animation: false,
          xAxis: [{
            type: 'category',
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
            nameTextStyle: {
              color: '#82b0ec',
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#82b0ec',
              },
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
              },
              margin: 30,
            },
          }, ],
          yAxis: [{
            show: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#fff',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#0c2c5a',
              },
            },
            axisLine: {
              show: false,
            },
          }, ],
          series: [{
              name: '',
              type: 'pictorialBar',
              symbolSize: [40, 10],
              symbolOffset: [0, -6], // 上部椭圆
              symbolPosition: 'end',
              z: 12,
              // "barWidth": "0",
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  // "formatter": "{c}%"
                  fontSize: 15,
                  fontWeight: 'bold',
                  color: '#34DCFF',
                },
              },
              color: '#2DB1EF',
              data: yData,
            },
            {
              name: '',
              type: 'pictorialBar',
              symbolSize: [40, 10],
              symbolOffset: [0, 7], // 下部椭圆
              // "barWidth": "20",
              z: 12,
              color: '#2DB1EF',
              data: yData,
            },
            {
              name: '',
              type: 'pictorialBar',
              symbolSize: function(d) {
                return d > 0 ? [50, 15] : [0, 0]
              },
              symbolOffset: [0, 12], // 下部内环
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: '#2EA9E5',
                  borderType: 'solid',
                  borderWidth: 1,
                },
              },
              data: yData,
            },
            {
              name: '',
              type: 'pictorialBar',
              symbolSize: [70, 20],
              symbolOffset: [0, 18], // 下部外环
              z: 10,
              itemStyle: {
                normal: {
                  color: 'transparent',
                  borderColor: '#19465D',
                  borderType: 'solid',
                  borderWidth: 2,
                },
              },
              data: yData,
            },
            {
              type: 'bar',
              //silent: true,
              barWidth: '40',
              barGap: '10%', // Make series be overlap
              barCateGoryGap: '10%',
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
                      offset: 0,
                      color: '#38B2E6',
                    },
                    {
                      offset: 1,
                      color: '#0B3147',
                    },
                  ]),
                  opacity: 0.8,
                },
              },
              data: yData,
            },
          ],
        };

        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      chaday(e) {
        this.dynamic = e
       let Authorization = window.sessionStorage.getItem("Authorization");
       this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 1, {
         headers: {
           'token': Authorization
         }
       
       }).then((res) => {
         console.log(res, 'sadasdas')
         if (res.data.code == 401) {
           this.$router.push("/");
       
         } else {
          let newdata=[
           this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
           this.serviceJinXingCount = res.data.data.serviceJinXingCount,
           this.serviceMoney = res.data.data.serviceMoney,
           this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
           this.serviceWanChengCount = res.data.data.serviceWanChengCount,
           this.servicehelperOrderAllCount = Number(this.serviceJinXingCount + this.serviceDaiFuWuCount + this
             .serviceTuiKuanCount + this.serviceWanChengCount) 
          ]
          this.newdata=[...newdata]
           this.getEchartData()
         }
       })
      },
      month(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 2, {
          headers: {
            'token': Authorization
          }
        
        }).then((res) => {
          console.log(res, 'sadasdas')
          if (res.data.code == 401) {
            this.$router.push("/");
        
          } else {
           let newdata=[
            this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
            this.serviceJinXingCount = res.data.data.serviceJinXingCount,
            this.serviceMoney = res.data.data.serviceMoney,
            this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
            this.serviceWanChengCount = res.data.data.serviceWanChengCount,
            this.servicehelperOrderAllCount = Number(this.serviceJinXingCount + this.serviceDaiFuWuCount + this
              .serviceTuiKuanCount + this.serviceWanChengCount) 
           ]
           this.newdata=[...newdata]
            this.getEchartData()
          }
        })
      },
      yearsday(e) {
        this.dynamic = e
        this.getselectOrdersToTal()
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersStatistics?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'sadasdas')
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
           let newdata=[
            this.serviceDaiFuWuCount = res.data.data.serviceDaiFuWuCount,
            this.serviceJinXingCount = res.data.data.serviceJinXingCount,
            this.serviceMoney = res.data.data.serviceMoney,
            this.serviceTuiKuanCount = res.data.data.serviceTuiKuanCount,
            this.serviceWanChengCount = res.data.data.serviceWanChengCount,
            this.servicehelperOrderAllCount = Number(this.serviceJinXingCount + this.serviceDaiFuWuCount + this
              .serviceTuiKuanCount + this.serviceWanChengCount) 
           ]
           this.newdata=[...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 13000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 445px;
    bottom: 291px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>