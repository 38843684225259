<template>
  <div id="app" style="z-index: 9999;">
    <div class="table_body">
      <div class="table_th">
        <div class="tr1 th_style">支付宝账号</div>
        <div class="tr2 th_style" style="position: absolute;left: 55px;">提现金额</div>
        <div class="tr3 th_style" style="position: absolute;left: 52px;">提现状态</div>
        <div class="tr4 th_style" style="position: absolute;left: 417px;">支付宝名称</div>
      </div>
      <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
        <div class="table_tr" v-for="(item,index) in listData" :key="index">
          <div class="tr1 tr" v-if="item.bianhao!=null">{{item.bianhao}}</div>
          <div class="tr1 tr" v-else style="color: red;">未绑定</div>
          <div class="tr2 tr">{{item.title}}</div>
         <!-- <div class="tr3 tr">
            <span v-if="item.date==1" style="color: brown;">待转账</span>
            <span v-if="item.date==2" style="color: red;">转账成功</span>
            <span v-if="item.date==0" style="color: yellowgreen;">已拒绝</span>
          </div> -->
          <div class="tr3 tr" :style="{color:tixianMap[item.date].color}">
           {{tixianMap[item.date].name}}
           <!-- {{item.date}} -->
          </div>
          <div class="tr4 tr" v-if="item.name!=null">{{item.name}}</div>
          <div class="tr4 tr" v-else style="color: aqua;">未绑定</div>
        </div>
      </vue-seamless-scroll>
    </div>

  </div>

</template>


<script>
  import {tixianMap,guanliMap} from "../../commom/data dictionary.js"
  export default {
    data() {
      return {
        tixianMap,
        guanliMap,
        userNum: '',
        listData: []
      }
    },
    computed: {
      optionHover() {
        return {
          step: 0.5, // 数值越大速度滚动越快
          limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)

        };
      },

    },
    methods: {
      ///cash/selectAdminHelpProfit?page=1&limit=10&zhifubaoName=&zhifubao=&phone=&startTime=&endTime=&classify=
      getserve() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //提现管理
        this.$axios.get('/cash/selectAdminHelpProfit?page=' + 1 + '&limit=' + 200 + '&zhifubaoName=&zhifubao=&phone=&startTime=&endTime=&classify=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          console.log(res, '提现管理')
          if (res.data.code == 401) {
            this.$router.push("/");
               
          } else {
            let ress = res.data.data.records
            this.userNew = ress
            var arrNew = []
            arrNew = this.userNew.map((item) => {
              return {
                bianhao: item.zhifubao, //商品名称
                name: item.zhifubaoName, //收货人姓名
                title: item.money, //地址
                date: item.state //价格
              }
            })
            this.listData = arrNew
          }

        })


      }

    },
    mounted() {
      this.getserve()
    },
  }
</script>
<style lang="less" scoped>
  .table_th {
    //头部
    width: 96%;
    display: flex;
    height: 70px;
    line-height: 117px;

  }

  .tr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 0.1125rem;
    text-align: center;
    font-size: 20px;
  }

  .tr1 {
    width: 30%;
  }

  .tr2 {
    width: 19%;
  }

  .tr3 {
    width: 35%;
    font-size: 20px;
  }

  .tr4 {
    flex: 1;

  }

  .th_style {
    color: rgb(0, 221, 253);
    font-weight: bold;
    font-size: 23px;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 41px;
    text-align: center;
  }


  .seamless-warp {
    //整体的高
    height: 577px;
    width: 564px;
    margin-left: 10px;
    overflow: hidden;

    ul li {
      list-style-type: none;
    }
  }

  .table_tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #eee;
    font-size: 20px;
    background: rgba(3, 145, 167, 0.1);
    border: 0.025rem solid rgb(4, 114, 131);
    border-right: none;
    border-left: none;
    margin-top: 0.375rem;
  }

  .el-table .cell {
    text-align: center;
  }

  .toptitle {
    width: 90%;
    display: flex;
    background-color: gainsboro;
    margin-bottom: 0.625rem;
    border: 0.125rem solid gainsboro;
    background-color: transparent;
    color: white
  }

  .item {
    width: 90%;
    text-align: center;
    position: relative;
    display: flex;
    line-height: 0.5rem;
    height: 0.5rem;
    color: #eee;
    background: rgba(3, 145, 167, 0.1);
    border: 0.0625rem solid rgb(4, 114, 131);
    margin-top: 0.4375rem;

    .date {
      width: 33%;
      position: absolute;
      left: -0.2rem;
      color: aqua;
    }

  }
</style>