<template>
  <div class="shuju">
    <div class="shuju1">
      <div class="text">
        会员用户
      </div>
      <div class="num">
        {{num1}}人
      </div>
    </div>
    <div class="shuju2">
      <div class="text2">
        普通用户
      </div>
      <div class="num2">
        {{num2}}人
      </div>
      <div class="text3">
        商家用户
      </div>
      <div class="num3">
        {{num3}}人
      </div>
      <div class="text4">
        师傅用户
      </div>
      <div class="num4">
        {{num4}}人
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        Timer: '',
        time: ''
      }
    },
    methods: {
      getdataCentre() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('user/selectUserList?page=' + 1 + '&limit=' + 10 +
          '&isCompany=&phone=&userName=&isAuthentication=' + 0 +
          '&shopType=&companyName=&invitationCode=&inviterCode=&isVip=' + 1, {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num1 = res.data.data.totalCount
          }
        })
        this.$axios.get('user/selectUserList?page=' + 1 + '&limit=' + 10 + '&isCompany=' + 2 +
          '&phone=&userName=&isAuthentication=' + 1 + '&shopType=&companyName=&invitationCode=&inviterCode=&isVip=' +
          2, {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num2 = res.data.data.totalCount
          }
        })
        this.$axios.get('user/selectUserList?page=' + 1 + '&limit=' + 10 +
          '&isCompany=&phone=&userName=&isAuthentication=' + 2 + '&shopType=' + 2 +
          '&companyName=&invitationCode=&inviterCode=&isVip=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num3 = res.data.data.totalCount
          }
        })
        this.$axios.get('user/selectUserList?page=' + 1 + '&limit=' + 10 +
          '&isCompany=&phone=&userName=&isAuthentication=' + 2 + '&shopType=' + 1 +
          '&companyName=&invitationCode=&inviterCode=&isVip=', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num4 = res.data.data.totalCount
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
        console.log(this.time, 11111)
      },
    },
    mounted() {
      this.getdataCentre()
      this.Timer = setInterval(this.getdataCentre, 20000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);

    }
  }
</script>

<style scoped lang="less">
  .shuju {
    width: 100%;
    height: 100%;
    position: relative;

    .shuju1 {
      width: 41%;
      height: 20%;
      background-image: url('../../assets/img/27-zbk1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 90px;
      left: 143px;
    }

    .shuju2 {
      width: 100%;
      height: 80%;
      background-image: url('../../assets/img/13-zbk03.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 98px;
    }
  }

  .text {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 9px;
  }

  .num {
    width: 100px;
    text-align: center;
    color: goldenrod;
    font-size: 23px;
    position: absolute;
    top: 20px;
    left: 115px;
  }

  .text2 {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 122px;
    left: 192px;
  }

  .num2 {
    width: 100px;
    text-align: center;
    color: goldenrod;
    font-size: 23px;
    position: absolute;
    top: 91px;
    left: 187px;
  }

  .text3 {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 48px;
    left: 34px;
  }

  .num3 {
    width: 100px;
    text-align: center;
    color: goldenrod;
    font-size: 23px;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .text4 {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 49px;
    left: 374px;
  }

  .num4 {
    width: 100px;
    text-align: center;
    color: goldenrod;
    font-size: 23px;
    position: absolute;
    top: 20px;

    left: 356px;

  }
</style>