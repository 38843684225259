<template>
  <div class="title">
    <div class="title_bt">
      家政服务6.0数据面板
    </div>
   <router></router>
  </div>
</template>

<script>
  import router from "../components/router/router.vue"
  export default {
    components: {
      router
    },
    data() {
      return {

      }
    },
  }
</script>

<style scoped lang="less">
  .title {
    width: 100%;
    height: 100px;
    background-image: url('../assets/img/02-btk.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
   
    .title_bt {
         font-size: 53px;
         font-family: 'Times New Roman', Times, serif;
         font-weight: 900;
         color: aliceblue;
         position: absolute;
         top: 18px;
         left: 727px;
    }
  }
</style>
