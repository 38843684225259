<template>
  <div class="concent">
    <div class="concent_1">
      <a href="#" class="text">累计提现总金额</a>
      <bing1></bing1>
    </div>
    <div class="concent_2">
      <a href="#" class="text">已提现金额</a>
      <bing2></bing2>
    </div>
    <div class="concent_3">
      <a href="#" class="text">总提现次数</a>

      <bing3></bing3>
    </div>
  </div>
</template>

<script>
  import bing1 from './cpjs3bing/bing1.vue'
  import bing2 from './cpjs3bing/bing2.vue'
  import bing3 from './cpjs3bing/bing3.vue'
  export default {
    components: {
      bing1,
      bing2,
      bing3
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="less">
  .concent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
     .text{
       width: 270px;
       line-height: 90px;
       font-size: 36px;
       text-decoration: none;
       color: #fff;
     }
    .concent_1 {
      width: 33.3%;
      height: 100%;
      background-image: url('../../assets/img/17-zbk05.png');
      background-repeat: no-repeat;
    }

    .concent_2 {
      width: 33.3%;
      height: 100%;
      background-image: url('../../assets/img/18-zbk06.png');
      background-repeat: no-repeat;
    }

    .concent_3 {
      width: 33.3%;
      height: 100%;
      background-image: url('../../assets/img/19-zbk07.png');
      background-repeat: no-repeat;
    }
  }
  
</style>
