const tixianMap={
    '1':{name:'已转账',color:'brown'},
      '0':{name:'待转账',color:'yellowgreen'},
     '-1':{name:'已拒绝',color:'red'}
}
const guanliMap={
    '1':{name:'充值成功',color:'#F56C6C'},
    '0':{name:'待充值',color:'#67C23A'}
}
// export default tixianMap
export {tixianMap,guanliMap} 
