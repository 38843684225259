<template>
  <div class="homee">
    <div class="query">
      <a class="btn" @click="chaday(1)" :class="{active:dynamic==1}">天</a>
      <a class="btn" @click="month(2)" :class="{active:dynamic==2}">月</a>
      <a class="btn-secondary" @click="yearsday(3)" :class="{active:dynamic==3}"> 年
      </a>
    </div>
    <div :style="{ width: '530px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        helperOrderAllCount: '', //万能任务总订单数
        helperOrderAllMoney: '', //万能任务总金额
        helperOrderCompleteCount: '', //万能任务完成总数
        helperOrderCompleteMoney: '', //万能任务完成总金额
        sendOrderCount: '', //服务接单数量
        sendOrderSum: '', //服务接单总金额
        takeOrderCount: '', //服务发单数量
        takeOrderSum: '', //服务发单总金额
        //传的参值
        dynamic: 3,
        Timer: ""
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          grid: {
            top: "28%",
            bottom: "10%",
            left:'16%',
            right:'8%'
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true
              }
            }
          },
          legend: {
            data: ["万能任务", "服务接(发)单"],
            top: "18%",
            textStyle: {
              fontSize: 15,
              color: "#ffffff"
            }
          },
          xAxis: {
            data: ['总订单数', '总金额', '完成总数', '完成总金额', '数量', '总金额', '数量', '总金额'],
            axisLine: {
              show: false //隐藏X轴轴线
            },
            axisTick: {
              show: false //隐藏X轴刻度
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ebf8ac" //X轴文字颜色
              }
            }
          },
          yAxis: [{
              type: "value",
              name: "元",
               position: "right",
              nameTextStyle: {
                color: "#ebf8ac"
              },
              splitLine: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#ebf8ac"
                }
              }
            },
            {
              type: "value",
              name: "元",
              nameTextStyle: {
                color: "#ebf8ac"
              },
              position: "left",
              splitLine: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                formatter: "{value} ", //右侧Y轴文字显示
                textStyle: {
                  color: "#ebf8ac"
                }
              }
            },
            {
              type: "value",
              splitLine: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false
              },
              splitArea: {
                show: true,
                areaStyle: {
                  color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"]
                }
              }
            }
          ],
          series: [{
              name: "万能任务",
              type: "line",
              yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
              smooth: true, //平滑曲线显示
              showAllSymbol: true, //显示所有图形。
              symbol: "circle", //标记的图形为实心圆
              symbolSize: 10, //标记的大小
              itemStyle: {
                //折线拐点标志的样式
                color: "#058cff"
              },
              lineStyle: {
                color: "#058cff"
              },
              areaStyle: {
                color: "rgba(5,140,255, 0.2)"
              },
              data: [this.helperOrderAllCount, this.helperOrderAllMoney, this.helperOrderCompleteCount, this
                .helperOrderCompleteMoney, 0, 0, 0, 0
              ]
            },
            {
              name: "服务接(发)单",
              type: "bar",
              barWidth: 15,
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: "#6f3d9e"
                    },
                    {
                      offset: 1,
                      color: "#3637a3"
                    }
                  ])
                }
              },
              data: [0, 0, 0, 0, this.sendOrderCount, this.sendOrderSum, this.takeOrderCount, this.takeOrderSum]
            }
          ]
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > 8) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      chaday(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/orderTaking/taskAnalysis?time=' + this.year + '&flag=' + 1, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, '王能分析(年，月dadas)')
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.helperOrderAllCount = res.data.data.helperOrderAllCount
            this.helperOrderAllMoney = res.data.data.helperOrderAllMoney
            this.helperOrderCompleteCount = res.data.data.helperOrderCompleteCount
            this.helperOrderCompleteMoney = res.data.data.helperOrderCompleteMoney
            this.sendOrderCount = res.data.data.sendOrderCount
            this.sendOrderSum = res.data.data.sendOrderSum
            this.takeOrderCount = res.data.data.takeOrderCount
            this.takeOrderSum = res.data.data.takeOrderSum
            this.getEchartData()

          }
        })
      },
      month(e) {
        this.dynamic = e
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/orderTaking/taskAnalysis?time=' + this.year + '&flag=' + 2, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, '王能分析(年，月dadas)')
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.helperOrderAllCount = res.data.data.helperOrderAllCount
            this.helperOrderAllMoney = res.data.data.helperOrderAllMoney
            this.helperOrderCompleteCount = res.data.data.helperOrderCompleteCount
            this.helperOrderCompleteMoney = res.data.data.helperOrderCompleteMoney
            this.sendOrderCount = res.data.data.sendOrderCount
            this.sendOrderSum = res.data.data.sendOrderSum
            this.takeOrderCount = res.data.data.takeOrderCount
            this.takeOrderSum = res.data.data.takeOrderSum
            this.getEchartData()

          }
        })
      },
      yearsday(e) {
        this.dynamic = e
        this.getselectOrdersToTal()
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/orderTaking/taskAnalysis?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          //console.log(res, '王能分析(年，月dadas)')
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.helperOrderAllCount = res.data.data.helperOrderAllCount
            this.helperOrderAllMoney = res.data.data.helperOrderAllMoney
            this.helperOrderCompleteCount = res.data.data.helperOrderCompleteCount
            this.helperOrderCompleteMoney = res.data.data.helperOrderCompleteMoney
            this.sendOrderCount = res.data.data.sendOrderCount
            this.sendOrderSum = res.data.data.sendOrderSum
            this.takeOrderCount = res.data.data.takeOrderCount
            this.takeOrderSum = res.data.data.takeOrderSum
            this.getEchartData()

          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 20000);


    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);

    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;
  }

  .query {
    width: 100px;
    display: flex;
    position: absolute;
    text-align: center;
    left: 445px;
    top: 76px;
    color: floralwhite;
    z-index: 9999;

    .btn {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .btn-secondary {
      width: 50%;
      background-image: url('../../assets/img/22weixz.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .active {
      background-image: url('../../assets/img/21xaunzhong.png');
    }
  }
</style>