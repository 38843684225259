<template>
  <div class="homee">
    <div class="frame">
      <!-- 左边 -->
      <div class="homee_left">
        <div class="homee_left_top">
          <a href="#" class="text1">服务分析</a>
         <homee1></homee1>
        </div>
        <div class="homee_left_middle">
          <a href="#" class="text2">服务收入分析</a>
          <homee2></homee2>
        </div>
        <div class="homee_left_bottom">
          <a href="#" class="text3">订单统计(服务订单)</a>
         <homee3></homee3>
        </div>
      </div>
      <!-- 中间 -->
      <div class="homee_middle">
        <div class="homee_middle_aaa">
        <shuju7></shuju7>
        </div>
        <div class="homee_middle_bottom">
          <a href="#" class="text6">支付统计</a>
          <homee5></homee5>
        </div>
      </div>
      <!-- 右边 -->
      <div class="homee_right">
        <div class="homee_right_top">
          <a href="#" class="text7">订单统计(万能任务)</a>
         <homee6></homee6>
        </div>
        <div class="homee_right_middle">
          <a href="#" class="text8">订单分析(服务订单)</a>
         <homee7></homee7>
        </div>
        <div class="homee_right_bottom">
          <a href="#" class="text9">订单分析(万能任务)</a>
         <homee8></homee8>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import homee1 from "../../views/homee/homee1.vue"
  import homee2 from "../../views/homee/homee2.vue"
  import homee3 from "../../views/homee/homee3.vue"
  import homee4 from "../../views/homee/homee9.vue"
  import homee5 from "../../views/homee/homee5.vue"
  import homee6 from "../../views/homee/homee6.vue"
  import homee7 from "../../views/homee/homee7.vue"
  import homee8 from "../../views/homee/homee8.vue"
  import shuju7 from "../../views/shujunum/shuju7.vue"
  export default {
    components:{
      homee1,homee2,homee3,homee4,homee5,homee6,homee7,homee8,shuju7
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="less">
  .homee {
    width: 100%;
    height: 100%;

    .frame {
      width: 100%;
      height: 94.7%;
      background-image: url('../../assets/img/03-dbk.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -36px;
      display: flex;

      .homee_left {
        width: 28%;
        height: 92.5%;
        margin: 43px 0 0 37px;
        display: flex;
        flex-wrap: wrap;

        .homee_left_top {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .homee_left_middle {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .homee_left_bottom {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      .homee_middle {
        flex: 1;
        height: 92.5%;
        margin: 43px 0 0 33px;
        display: flex;
        flex-wrap: wrap;

        .homee_middle_aaa {
          width: 100%;
          height: 70%;
          // background-color: antiquewhite;
        }

        .homee_middle_middle {
          width: 100%;
          height: 49%;
          background-image: url('../../assets/img/09-zsjdc.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .homee_middle_bottom {
          width: 100%;
          height: 30%;
          background-image: url('../../assets/img/10-zjk04.png');
          background-repeat: no-repeat;
          background-size: 100% 120%;


        }
      }

      .homee_right {
        width: 28%;
        height: 92.5%;
        margin: 43px 49px 0 0;
        display: flex;
        flex-wrap: wrap;

        .homee_right_top {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .homee_right_middle {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .homee_right_bottom {
          width: 100%;
          height: 33.3%;
          background-image: url('../../assets/img/04-zjk01.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .text1 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 70px;
    left: 75px;
  }

  .text2 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 383px;
    left: 75px;
  }

  .text3 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 701px;
    left: 75px;
  }
  .text6 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 710px;
    right: 911px;
  }

  .text7 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 70px;
   right: 330px;
  }

  .text8 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 386px;
    right: 330px;
  }

  .text9 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 700px;
    right: 330px;
  }
</style>
