<template>
  <div class="cpjs">
    <div :style="{ width: '630px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        zfbAllCount: '', //支付宝提现总金额
        zfbAllMoney: '', //支付宝提现总金额
        zfbRefuseCount: '', //支付宝已驳回次数
        zfbRefuseMoney: '', //支付宝已驳回金额
        zfbTraverseCount: '', //支付宝已提现次数
        zfbTraverseMoney: '', //支付宝已提现金额
        zfbWaitCount: '', //支付宝待提现次数
        zfbWaitMoney: '', //支付宝待提现金额
        Timer: '',
        newdata: ''
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
          title: {
            text: '提现支付宝表',
            textStyle: {
              align: 'center',
              color: '#fff',
              fontSize: 20,
            },
            top: '20%',
            left: '50%',
          },
          grid: {
            top: "25%",
            bottom: "17%",
            left: '10%',
            right: '10%'
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                // show: true
              }
            },

          },
          xAxis: {
            
            data: ['总次数', '总金额', '已驳回次数', '已驳回金额', '已提现次数', '已提现金额', '待提现次数', '待提现金额'],
            axisLine: {
              show: false, //隐藏X轴轴线
              lineStyle: {
                color: '#01FCE3'
              }
            },
            axisTick: {
              show: false //隐藏X轴刻度
            },
            axisLabel: {
              show: true,
              color: "#fff" //X轴文字颜色
            },

          },
          yAxis: [{
              type: "value",
              name: "元",
              nameTextStyle: {
                color: "#ebf8ac"
              },
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#FFFFFF'
                }
              },
              axisLabel: {
                show: true,
                color: "#fff"
              },

            },
            {
              type: "value",
              name: "元",
              show: false,
              nameTextStyle: {
                color: "#ebf8ac"
              },
              position: "right",
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                formatter: "{value} ", //右侧Y轴文字显示
                color: ""
              }
            }
          ],
          series: [{
              name: "提现次数(元/笔)",
              type: "line",
              yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
              smooth: true, //平滑曲线显示
              showAllSymbol: true, //显示所有图形。
              symbol: "circle", //标记的图形为实心圆
              symbolSize: 8, //标记的大小
              itemStyle: {
                //折线拐点标志的样式
                // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [{
                    offset: 0,
                    color: '#fff' // 0% 处的颜色
                  }, {
                    offset: 0.25,
                    color: '#fff' // 100% 处的颜色
                  }, {
                    offset: 0.5,
                    color: '#058cff' // 100% 处的颜色
                  }, {
                    offset: 1,
                    color: 'red' // 100% 处的颜色
                  }],
                  global: false, // 缺省为 false
                  emphasis: {
                    color: '#EB547C',
                    borderColor: '#EB547C',
                    borderWidth: 40,
                  }
                }
              },
              lineStyle: {
                color: "#058cff"
              },
              areaStyle: {
                color: "rgba(5,140,255, 0)"
              },
              data: this.newdata
            },
            {
              // name: "主营业务",
              type: "bar",
              barWidth: 1,
              tooltip: {
                show: false
              },
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "#1268f3"
                  },
                  {
                    offset: 0.5,
                    color: "#4693EC"
                  },
                  {
                    offset: 1,
                    color: "rgba(250, 250, 250, 0)"
                  }
                ])
              },
              data: this.newdata
            }
          ]
        }

        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getstatisticsMoney() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/cash/statisticsCashMoney?time=' + this.time + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          console.log(res, '40030030总金额')
          if (res.data.code == 401) {
            this.$router.push('/')
          } else {
            let newdata = [
              this.zfbAllCount = res.data.data.zfbAllCount,
              this.zfbAllMoney = res.data.data.zfbAllMoney,
              this.zfbRefuseCount = res.data.data.zfbRefuseCount,
              this.zfbRefuseMoney = res.data.data.zfbRefuseMoney,
              this.zfbTraverseCount = res.data.data.zfbTraverseCount,
              this.zfbTraverseMoney = res.data.data.zfbTraverseMoney,
              this.zfbWaitCount = res.data.data.zfbWaitCount,
              this.zfbWaitMoney = res.data.data.zfbWaitMoney
            ]
            this.newdata = [...newdata]
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getstatisticsMoney()
      this.Timer = setInterval(this.getstatisticsMoney, 20000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .cpjs {
    width: 100%;
    height: 100%;
  }
</style>