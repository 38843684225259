<template>
  <div class="concent">
    <!-- 左边 -->
    <div class="people_1">
      <div class="people_1_1">
        <a href="#" class="text1">派单管理</a>
        <people1></people1>
      </div>
      <div class="people_1_2">
        <a href="#" class="text2">派单列表</a>
        <people2></people2>
      </div>
      <div class="people_1_3">
        <a href="#" class="text3">派单管理服务类型</a>
        <people3></people3>
      </div>
    </div>
    <!-- 中间 -->
    <div class="people_2">
      <div class="people_2_1">
        <people4></people4>
      </div>
    </div>
    <!-- 右边 -->
    <div class="people_3">
      <div class="people_3_1">
        <a href="#" class="text5">接单服务中心</a>
        <people6></people6>
      </div>
      <div class="people_3_2">
        <a href="#" class="text7">接单服务类型</a>
          <people7></people7>
      </div>
      <div class="people_3_3">
        <a href="#" class="text6">商家列表</a>
       <people8></people8>
      </div>
    </div>
  </div>
</template>

<script>
  import people1 from "../../views/people/people1.vue"
  import people2 from "../../views/people/people2.vue"
  import people3 from "../../views/people/people3.vue"
  import people4 from "../../views/people/people4.vue"
  import people6 from "../../views/people/people6.vue"
  import people7 from "../../views/people/people7.vue"
  import people8 from "../../views/people/people8.vue"
  export default {
    components: {
      people1,
      people2,
      people3,
      people4,
      people6,
      people7,
      people8,
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="less">
  .concent {
    width: 97%;
    height: 980px;
    // margin-left: 30px;

    display: flex;
    position: relative;

    .people_1 {
      width: 27%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .people_1_1 {
        width: 100%;
        height: 35.3%;
        margin-bottom: 10px;
        background-image: url('../../assets/img/04-zjk01.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .people_1_2 {
        width: 100%;
        height: 31%;
        margin-bottom: 10px;
        background-image: url('../../assets/img/04-zjk01.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .people_1_3 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/img/04-zjk01.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }

    .people_2 {
      flex: 1;
      height: 100%;
      margin-left: 30px;

      .people_2_1 {
        width: 100%;
        height: 100%;
        margin-bottom: 5px;
      }
    }

    .people_3 {
      width: 27%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      .people_3_1 {
        width: 100%;
        height: 33.3%;
        margin-bottom: 10px;
        background-image: url('../../assets/iimg/zujiankuagxia.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: 20px;
      }

      .people_3_2 {
        width: 100%;
        height: 33.3%;
        margin-bottom: 10px;
        background-image: url('../../assets/img/04-zjk01.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .people_3_3 {
        width: 100%;
        height: 33.3%;
        margin-bottom: 10px;
        background-image: url('../../assets/img/04-zjk01.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .text1 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 26px;
    left: 33px;
  }

  .text2 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 381px;
    left: 33px;
  }

  .text3 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 698px;
    left: 33px;
  }

  .text4 {
    text-decoration: none;
    font-size: 19px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 733px;
    left: 872px;
  }

  .text5 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: -2px;
        right: 306px;
  }

  .text6 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 700px;
    right: 316px;
  }

  .text7 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 365px;
    right: 369px;
  }
</style>