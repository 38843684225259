<template>
  <div id="app" style="z-index: 9999;">
    <div class="table_body">
      <div class="table_th">
        <div class="tr1 th_style" style="margin-left: 15px;">编号</div>
        <div class="tr2 th_style" style="margin-left: -10px;">用户名</div>
        <div class="tr3 th_style" style="margin-left: -22px;">派单人金额</div>
        <div class="tr4 th_style" style="margin-left: 3px;">支付金额</div>
        <div class="tr5 th_style" style="margin-left: -10px;">平台收益(h)</div>
      </div>
      <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
        <div class="table_tr" v-for="(item,index) in listData" :key="index">
          <div class="tr1 tr">{{item.bianhao}}</div>
         <!-- <div class="tr2 tr">{{item.name}}</div> -->
         <div class='tr2 tr'>
           <span v-if="item.name==null">未设置</span>
           <span v-else-if>{{item.name}}</span>
         </div>
          <div class="tr3 tr">{{item.date}}</div>
          <div class="tr4 tr">{{item.title}} </div>
          <div class="tr5 tr">{{item.money}} </div>
        </div>
      </vue-seamless-scroll>
    </div>

  </div>

</template>
<script>
  export default {
    data() {
      return {
        liColor: ['#ccc'],
        userNum: '',
        listData: [],
      }
    },
    computed: {
      optionHover() {
        return {
          step: 0.5, // 数值越大速度滚动越快
          limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)

        };
      },

    },
    methods: {
      //ttps://jiazhengadminv5.xianmaxiong.com/sqx_fast/orderTaking/incomeAnalysis?page=1&limit=5&time=2023-04-06&flag=3
      getuser() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/orderTaking/incomeAnalysis?page=' + 1 + '&limit=' + 100 + '&time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }
        }).then((res) => {

          if (res.data.code == 401) {
            this.$router.push('/');
          } else {
            let ress = res.data.data.list
         
            this.userNum = ress
            var arrNew = []
            arrNew = this.userNum.map((item) => {
              return {
                bianhao: item.orderId, //编号
                name: item.userName, //下单用户
                title: item.payMoney, //地址
                date: item.money, //价格
                money: Number(item.payMoney - item.money)
              }
            })
            this.listData = arrNew
               console.log(this.listData, '8989090')
          }

        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },
    },
    mounted() {
      this.getuser()
    },
    created() {
      this.getDate()
    },
  }
</script>
<style lang="less" scoped>
  .table_th {
    //头部
    width: 96%;
    display: flex;
    height: 38px;
    line-height: 155px;

  }

  .tr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
  }

  .tr1 {
    width: 20%;
  }

  .tr2 {
    width: 20%;
  }

  .tr3 {
    width: 15%;
  }

  .tr4 {
    width: 23%;

  }

  .tr5 {
    flex: 1;

  }

  .th_style {
    color: rgb(0, 221, 253);
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 50px;
    text-align: center;
  }


  .seamless-warp {
    //整体的高
    width: 96%;
    height: 222px;
    margin-top: 58px;
    overflow: hidden;
    margin-left: 26px;

    ul li {
      list-style-type: none;
    }
  }

  .table_tr {
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #eee;
    font-size: 20px;
    background: rgba(3, 145, 167, 0.1);
    border: 0.025rem solid rgb(4, 114, 131);
    border-right: none;
    border-left: none;
    margin-top: 0.375rem;
  }

  .el-table .cell {
    text-align: center;
  }

  .toptitle {
    width: 90%;
    display: flex;
    background-color: gainsboro;
    margin-bottom: 0.625rem;
    border: 0.125rem solid gainsboro;
    background-color: transparent;
    color: white
  }

  .item {
    width: 90%;
    text-align: center;
    position: relative;
    display: flex;
    line-height: 0.5rem;
    height: 0.5rem;
    color: #eee;
    background: rgba(3, 145, 167, 0.1);
    border: 0.0625rem solid rgb(4, 114, 131);
    margin-top: 0.4375rem;

    .date {
      width: 33%;
      position: absolute;
      left: -0.2rem;
      color: aqua;
    }

  }

  .liColor {
    color: red;
  }
</style>