<template>
  <div class="shujunumtop_middle">
    <div class="shujunumtop_middle_1">
      <shuju6></shuju6>
      <div class="shujunumtop_middle_1_1">
        <div class="text">
          总用户数
        </div>
        <div class="unit">
          人
        </div>
        <div class="num">
          {{num1}}
        </div>
      </div>
      <div class="shujunumtop_middle_1_2">
        <div class="text">
          今日新增
        </div>
        <div class="unit">
          人
        </div>
        <div class="num">
          {{num2}}
        </div>
      </div>
      <div class="shujunumtop_middle_1_3">
        <div class="text">
          本月新增
        </div>
        <div class="unit">
          人
        </div>
        <div class="num">
          {{num3}}
        </div>
      </div>
    </div>
    <div class="shujunumtop_middle_2">

    </div>
    <div class="shujunumtop_middle_3">
      <div class="shujunumtop_middle_3_1">
        <div class="text" style="position: absolute; left: 110px;">
          本年新增
        </div>
        <div class="unit" style="position: absolute; left: 110px;">
          人
        </div>
        <div class="num" style="position: absolute; left: 110px;">
          {{num4}}
        </div>
      </div>
      <div class="shujunumtop_middle_3_2">
        <div class="text" style="position: absolute; left: 110px; font-size: 17px;">
          总充值
        </div>
        <div class="unit" style="position: absolute; left: 110px;">
          元
        </div>
        <div class="num" style="position: absolute; left: 110px;">
          {{num5}}
        </div>
      </div>
      <div class="shujunumtop_middle_3_3">
        <div class="text" style="position: absolute; left: 110px;font-size: 17px;">
          今日充值
        </div>
        <div class="unit" style="position: absolute; left: 110px;">
          元
        </div>
        <div class="num" style="position: absolute; left: 110px;">
          <span v-if="num6==null">0</span>
          <span v-else-if>{{num6}}</span>
        </div>
      </div>
      <div class="shujunumtop_middle_3_4">
        <div class="text" style="position: absolute; left: 110px;font-size: 17px;">
          本月充值
        </div>
        <div class="unit" style="position: absolute; left: 110px;">
          元
        </div>
        <div class="num" style="position: absolute; left: 110px;">
          <span v-if="num7==null">0</span>
          <span v-else-if>{{num7}}</span>
        </div>
      </div>
      <div class="shujunumtop_middle_3_5">
        <div class="text" style="position: absolute; left: 110px;font-size: 17px;">
          本年充值
        </div>
        <div class="unit" style="position: absolute; left: 110px;">
          元
        </div>
        <div class="num" style="position: absolute; left: 110px;">
          {{num8}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import shuju6 from "./shju6.vue" //饼图
  export default {
    components: {
      shuju6
    },
    data() {
      return {
        num1: 222,
        num2: 333,
        num3: 333,
        num4: 44,
        num5: 4444,
        num6: 888,
        num7: 888,
        num8: 888,
        Timer: ''

      }
    },
    methods: {
      getdataCentre() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //全部订单
        this.$axios.get('/user/homeMessage', {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {

            this.$router.push("/");
          } else {
            let ress = res.data.data
            //console.log(ress, '数据中心')
            this.num1 = ress.totalUsers
            this.num2 = ress.newToday
            this.num3 = ress.newMonth
            this.num4 = ress.newYear
            this.num5 = ress.totalRevenue
            this.num6 = ress.todayRevenue
            this.num7 = ress.monthRevenue
            this.num8 = ress.yearRevenue
          }

        })
      }
    },
    mounted() {
      this.getdataCentre()
      this.Timer = setInterval(this.getdataCentre, 2000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .shujunumtop_middle {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    .shujunumtop_middle_1 {
      width: 30%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;


      .shujunumtop_middle_1_1 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/8-iconzys.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 85px;
        left: 65px;
      }

      .shujunumtop_middle_1_2 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/9 xinchanpinyanfa.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 245px;
        left: 65px;
      }

      .shujunumtop_middle_1_3 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/10keyanrenyuan.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 413px;
        left: 65px;
      }
    }

    .shujunumtop_middle_2 {
      width: 56%;
      height: 56%;
      background-image: url('../../assets/iimg/7yuanhuan.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 24%;
      top: 20%
    }

    .shujunumtop_middle_3 {
      width: 30%;
      height: 100%;
      position: absolute;
      right: -46px;
      top: -22px;
      flex-wrap: wrap;

      .shujunumtop_middle_3_1 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/11zonghuafei.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 110px;
        left: -13px;
      }

      .shujunumtop_middle_3_2 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/12xinchagpingxiaoshou.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 262px;
        left: -13px;

      }

      .shujunumtop_middle_3_3 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/13zaikeyanxiangmu.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 425px;
        left: -16px;
      }

      .shujunumtop_middle_3_4 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/13zaikeyanxiangmu.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 52px;
        left: -231px;
      }

      .shujunumtop_middle_3_5 {
        width: 100%;
        height: 33.3%;
        background-image: url('../../assets/iimg/13zaikeyanxiangmu.png');
        background-repeat: no-repeat;
        position: absolute;
        top: 525px;
        left: -225px;
      }
    }
  }

  .text {
    color: skyblue;
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: -85px;
  }

  .unit {
    color: skyblue;
    font-size: 10px;
    position: absolute;
    top: 44px;
    left: -19px;
  }

  .num {
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 67px;
    left: -36px;
  }

  .texta {
    width: 155px;
    color: skyblue;
    font-size: 15px;
    position: absolute;
    top: 104px;
    left: -44px;
    text-align: center;
  }

  .unit1 {
    color: skyblue;
    font-size: 10px;
    position: absolute;
    top: 141px;
    left: 22px;
  }

  .num1 {
    width: 157px;
    color: #fff;
    font-size: 32px;
    position: absolute;
    top: 171px;
    left: -45px;
    text-align: center;
  }

  .text1 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 104px;
    left: 41px;
  }

  .text2 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 108px;
    right: 347px;
  }

  .text3 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 10px;
    left: 35px;
  }

  .text4 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 757px;
    left: 36px;
  }

  .text5 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    bottom: 292px;
    right: 1147px;
  }

  .text6 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 757px;
    right: 1061px;
  }
</style>