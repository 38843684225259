<template>
  <div class="homeeaa">
    <div class="homee_middle_top">
      <a href="#" class="text4">平台佣金统计</a>
    </div>
    <div class="homee_middle_middle">
      <homee4></homee4>
    </div>
  </div>
</template>

<script>
  import homee4 from "./homee4.vue"
  export default {
    components: {
      homee4
    }
  }
</script>

<style scoped lang="less">
  .homeeaa {
    width: 100%;
    height: 70%;
    flex: 1;
    // margin: 43px 0 0 33px;
    display: flex;
    flex-wrap: wrap;

    .homee_middle_top {
      width: 100%;
      height: 40%;
      background-image: url('../../assets/img/08-hxzb.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .homee_middle_middle {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/img/09-zsjdc.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .text4 {
    text-decoration: none;
    font-size: 44px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 144px;
    left: 854px;

  }
</style>
