<template>
  <div class="shujunum">
    <!-- 上 -->
    <div class="shujunumtop">
      <div class="shujunumtop_left">
        <a href="#" class="text1">用户列表</a>
        <shuju1></shuju1>
      </div>
      <div class="shujumiddle">
        <homee9></homee9>
      </div>
      <div class="shujunumtop_right">
        <div class="shujunumtop_right_1">
          <a href="#" class="text2">用户中心人数</a>
          <shuju2></shuju2>
        </div>
        <div class="shujunumtop_right_2">
          <a href="#" class="text3">用户中心</a>
          <div class="shujunumtop_right_2_1">
            <div class="texta">
              员工用户
            </div>
            <div class="unit1">
              人
            </div>
            <div class="num1">
              {{num7}}
            </div>
          </div>
          <div class="shujunumtop_right_2_2">
            <div class="texta">
              用户反馈
            </div>
            <div class="unit1">
              条
            </div>
            <div class="num1">
              {{num8}}
            </div>
          </div>
          <div class="shujunumtop_right_2_3">
            <div class="texta">
              商家商户数
            </div>
            <div class="unit1">
              家
            </div>
            <div class="num1">
              {{num9}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下 -->
    <div class="shujunumbottom">
      <div class="shujunumbottom_left">
        <a href="#" class="text4">用户分析</a>
        <shuju3></shuju3>
      </div>
      <div class="shujunumbottom_right">
        <a href="#" class="text6">消息投诉</a>
        <shuju5></shuju5>
      </div>
    </div>
  </div>
</template>

<script>
  import shuju1 from "../../views/shujunum/shju1.vue"
  import shuju2 from "../../views/shujunum/shju2.vue"
  import shuju3 from "../../views/shujunum/shju3.vue"
  import shuju4 from "../../views/shujunum/shju4.vue"
  import shuju5 from "../../views/shujunum/shju5.vue"
  import homee9 from "../../views/homee/homee9.vue"
  export default {
    components: {
      shuju1,
      shuju2,
      shuju3,
      shuju4,
      shuju5,
      homee9
    },
    data() {
      return {
        num7: 888,
        num8: 888,
        num9: 888,
        Timer: ''
      }
    },
    methods: {
      getdataCentre() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        //员工用户
        this.$axios.get('user/selectUserList?page=' + 1 + '&limit=' + 10 + '&isCompany=' + 1 +
          '&phone=&userName=&isAuthentication=' + 0 + '&shopType=&companyName=&invitationCode=&inviterCode=&isVip', {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            let ress = res.data.data
            //console.log(ress, 'asdsadsadsad中心')
            this.num7 = ress.totalCount
          }
        })
        //全部订单
        this.$axios.get('/userCertification/queryUserCertification?page=' + 1 + '&limit=' + 10 +
          '&name=&phone=&classify=' + 2, {
            headers: {
              'token': Authorization
            }
          }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            let ress = res.data.data
            //console.log(ress, '数据中心')
            this.num9 = ress.total
          }
        })
        //用户反馈
        this.$axios.get('/message/page/2/2/10', {
          headers: {
            'token': Authorization
          }
        }).then((res) => {
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num8 = res.data.data.totalCount
          }
        })
      }
    },
    mounted() {
      this.getdataCentre()
      this.Timer = setInterval(this.getdataCentre, 20000)
    },
    destroyed() {
      clearInterval(this.Timer)
    }
  }
</script>

<style scoped lang="less">
  .shujunum {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .shujunumtop {
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;

      .shujunumtop_left {
        width: 25%;
        height: 100%;
        background-image: url('../../assets/iimg/2zujiankuangzs.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .shujumiddle {
        width: 50%;
        height: 100%;
        display: flex;
        background-image: url('../../assets/iimg/2zujiankuangzshang.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }


      .shujunumtop_right {
        width: 25%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .shujunumtop_right_1 {
          width: 100%;
          height: 50%;
          background-image: url('../../assets/iimg/4zujiankuangyoushang.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .shujunumtop_right_2 {
          width: 100%;
          height: 50%;
          background-image: url('../../assets/iimg/5zujiangkuangyoumiddle.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;

          .shujunumtop_right_2_1 {
            width: 60px;
            height: 60px;
            background-image: url('../../assets/iimg/14bianzu1.png');
            background-repeat: no-repeat;
            position: absolute;
            top: 91px;
            left: 56px;
          }

          .shujunumtop_right_2_2 {
            width: 60px;
            height: 60px;
            background-image: url('../../assets/iimg/15bianzu2.png');
            background-repeat: no-repeat;
            position: absolute;
            top: 91px;
            left: 217px;
          }

          .shujunumtop_right_2_3 {
            width: 60px;
            height: 60px;
            background-image: url('../../assets/iimg/16bianzu3.png');
            background-repeat: no-repeat;
            position: absolute;
            top: 91px;
            left: 371px;
          }
        }
      }

    }

    .shujunumbottom {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;

      .shujunumbottom_left {
        width: 33.3%;
        height: 100%;
        background-image: url('../../assets/iimg/zujiankuagxia.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      // .shujunumbottom_middle {
      //   width: 33.3%;
      //   height: 100%;
      //   background-image: url('../../assets/iimg/zujiankuagxia.png');
      //   background-repeat: no-repeat;
      //   background-size: 100% 100%;
      // }

      .shujunumbottom_right {
        flex: 1;
        height: 100%;
        background-image: url('../../assets/iimg/zujiankuagxia.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .text {
    color: skyblue;
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: -85px;
  }

  .unit {
    color: skyblue;
    font-size: 10px;
    position: absolute;
    top: 44px;
    left: -19px;
  }

  .num {
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 67px;
    left: -36px;
  }

  .texta {
    width: 155px;
    color: skyblue;
    font-size: 15px;
    position: absolute;
    top: 104px;
    left: -44px;
    text-align: center;
  }

  .unit1 {
    color: skyblue;
    font-size: 10px;
    position: absolute;
    top: 141px;
    left: 22px;
  }

  .num1 {
    width: 157px;
    color: #fff;
    font-size: 32px;
    position: absolute;
    top: 171px;
    left: -45px;
    text-align: center;
  }

  .text1 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 104px;
    left: 41px;
  }

  .text2 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 108px;
    right: 299px;
  }

  .text3 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 10px;
    left: 35px;
  }

  .text4 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 757px;
    left: 36px;
  }

  .text5 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    bottom: 292px;
    right: 1147px;
  }

  .text6 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 757px;
    right: 1061px;
  }
</style>