<template>
  <div class="changpingjieshao">
    <div class="changpingjieshao_top">
      <div class="changpingjieshao_top_left">
        <a href="#" class="text1">提现管理</a>
       <cpjs1></cpjs1>
      </div>
      <div class="changpingjieshao_top_middle">
          <div class="changpingjieshao_top_middle_top">
           <cpjs2></cpjs2>
          </div>
          <div class="changpingjieshao_top_middle_bottom">
            <cpjs3></cpjs3>
          </div>
      </div>
      <div class="changpingjieshao_top_bottom">
        <a href="#" class="text2">提现统计(年)</a>
        <div class="changpingjieshao_top_bottom_top">
            <cpjs4></cpjs4>
        </div>
        <div class="changpingjieshao_top_bottom_bottom">
         <cpjs7></cpjs7>
        </div>
        
      </div>
    </div>
    <div class="changpingjieshao_bottom">
      <div class="changpingjieshao_bottom_left">
        <a href="#" class="text3">财务提现</a>
       <cpjs5></cpjs5>
      </div>
      <div class="changpingjieshao_bottom_right">
        <a href="#" class="text4">充值记录列表</a>
       <cpjs6></cpjs6>
      </div>
    </div>
  </div>
</template>

<script>
  import cpjs1 from "../../views/chanpingjieshao/cpjs1.vue"
  import cpjs2 from "../../views/chanpingjieshao/cpjs2.vue"
  import cpjs3 from "../../views/chanpingjieshao/cpjs3.vue"
  import cpjs4 from "../../views/chanpingjieshao/cpjs4.vue"
  import cpjs5 from "../../views/chanpingjieshao/cpjs5.vue"
  import cpjs6 from "../../views/chanpingjieshao/cpjs6.vue"
  import cpjs7 from "../../views/chanpingjieshao/cpjs7.vue"
  export default {
    components:{
      cpjs1,cpjs2,cpjs3,cpjs4,cpjs5,cpjs6,cpjs7
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped lang="less">
  .changpingjieshao {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .changpingjieshao_top {
      width: 100%;
      height: 60%;
      display: flex;

      .changpingjieshao_top_left {
        width: 30%;
        height: 100%;
        background-image: url('../../assets/iimg/2zujiankuangzs.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .changpingjieshao_top_middle {
        width: 40%;
        height: 100%;
        background-image: url('../../assets/iimg/2zujiankuangzshang.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-wrap: wrap;
        .changpingjieshao_top_middle_top{
          width: 100%;
          height: 40%;
        }
        .changpingjieshao_top_middle_bottom{
          width: 100%;
          height: 60%;
        }
      }

      .changpingjieshao_top_bottom {
        width: 30%;
        height: 100%;
        background-image: url('../../assets/iimg/2zujiankuangzs.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-wrap: wrap;
        .changpingjieshao_top_bottom_top{
          width: 100%;
          height: 50%;
          border-bottom: 1px skyblue solid;
        }
        .changpingjieshao_top_bottom_bottm{
          width: 100%;
          height: 50%;
        }
      }
    }

    .changpingjieshao_bottom {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;

      .changpingjieshao_bottom_left {
        width: 50%;
        height: 100%;
        background-image: url('../../assets/img/10-zjk04.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .changpingjieshao_bottom_right {
        width: 50%;
        height: 100%;
        background-image: url('../../assets/img/10-zjk04.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .text1 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 104px;
    left: 41px;
  }

  .text2 {
    text-decoration: none;
    font-size: 25px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 105px;
        right: 387px;
  }

  .text3 {
    text-decoration: none;
    font-size: 34px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 762px;
    left: 399px;
  }

  .text4 {
    text-decoration: none;
    font-size: 34px;
    font-weight: 800;
    color: white;
    position: absolute;
    top: 761px;
    right: 393px;
  }
</style>
