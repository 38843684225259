<template>
  <div class="home">
    <div class="home1">
      <div class="text">
        (服务任务)零钱支付
      </div>
      <div class="num">
        {{num1}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home2">
      <div class="text">
        (服务任务)微信支付
      </div>
      <div class="num">
        {{num2}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home3">
      <div class="text">
        (服务任务)支付宝支付
      </div>
      <div class="num">
        {{num3}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home4">
      <div class="text">
        (万能任务)零钱支付
      </div>
      <div class="num">
        {{num4}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home5">
      <div class="text">
        (万能任务)微信支付
      </div>
      <div class="num">
        {{num5}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
    <div class="home6">
      <div class="text">
        (万能任务)支付宝支付
      </div>
      <div class="num">
        {{num6}}
      </div>
      <div class="unit">
        元
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        Timer: '',
        num1: 2677,
        num2: 2677,
        num3: 2677,
        num4: 2677,
        num5: 2677,
        num6: 2677,
      }
    },
    methods: {
      ///orders/selectOrdersPayStatistics?time=2023-04-06&flag=3
      getorders() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('orders/selectOrdersPayStatistics?time=' + this.year + '&flag=' + 3, {
          headers: {
            'token': Authorization
          }

        }).then((res) => {
          console.log(res, 'da11111111111dasdsa')
          if (res.data.code == 401) {
            this.$router.push("/");
          } else {
            this.num4 = res.data.data.HelpOrderLingQianMoney //(万能任务)零钱支付
            this.num5 = res.data.data.HelpOrderWxMoney //(万能任务)微信支付
            this.num6 = res.data.data.HelpOrderZFBMoney //(万能任务)支付宝支付
            this.num1 = res.data.data.serviceLingQianMoney //(服务任务)零钱支付
            this.num3 = res.data.data.serviceWXMoney //(服务任务)支付宝支付
            this.num2 = res.data.data.serviceZFBMoney //(服务任务)微信支付


          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        if (dates >= 1 && dates <= 9) dates = '0' + dates;
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.year = year + "-" + month + "-" + dates
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getorders()
      this.Timer = setInterval(this.getorders, 13000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>

<style scoped lang="less">
  .home {
    width: 100%;
    height: 100%;
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
  }


  .home1 {
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
    margin-top: 10px;
  }

  .home2 {
    margin-top: 10px;
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
  }

  .home3 {
    margin-top: 10px;
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
  }

  .home4 {
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
  }

  .home5 {
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
  }

  .home6 {
    width: 33.3%;
    height: 41%;
    background-image: url('../../assets/img/17-zbk05.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
  }

  .text {
    width: 100%;
    text-align: center;
    color: skyblue;
    font-size: 24px;
  }

  .num {
    width: 100%;
    text-align: center;
    color: gold;
    font-size: 26px;
  }

  .unit {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 20px;
  }
</style>