<template>
  <div class="people">
    <div :style="{ width: '500px', height: '350px' }" ref="myChart"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num1: 22,
        num2: 220,
        num3: 2,
        Timer: ""
      }
    },
    methods: {
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);

        let label = ['悬赏任务', '报价任务', '一口价任务'];
        let value = [this.num1, this.num2, this.num3];

        var option = {
          grid: {
            top: '24%',
            right: '10%',
            left: '8%',
            bottom: '4%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0,
                      color: 'rgba(255,255,255,0)', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255,255,255,1)', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,255,255,0)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              formatter: '{value}',
              fontSize: 14,
              margin: 20,
              textStyle: {
                color: '#7ec7ff',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#243753',
              },
            },
            axisTick: {
              show: false,
            },
            data: label,
          }, ],
          yAxis: [{
            boundaryGap: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#7ec7ff',
              },
            },
            nameTextStyle: {
              color: '#fff',
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#283352',
              },
            },
            axisTick: {
              show: false,
            },
          }, ],
          series: [{
            name: '单',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 8,
            zlevel: 3,
            itemStyle: {
              color: '#19a3df',
              borderColor: '#a3c8d8',
            },
            lineStyle: {
              normal: {
                width: 6,
                color: '#19a3df',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                      offset: 0,
                      color: 'rgba(88,255,255,0.2)',
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(88,255,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
            data: value,
          }, ],
        };
        let currentIndex = 0;
        clearInterval(this.timer);
        this.timer = setInterval(function() {
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
          });
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: currentIndex

          });
          currentIndex++;
          if (currentIndex > option.series[0].data.length) {
            currentIndex = 0;
          }
        }, 2000);
        myChart.clear()
        myChart.setOption(option, true);
      },
      getselectOrdersToTal() {
        let Authorization = window.sessionStorage.getItem("Authorization");
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 2 +
          '&gameId=&startTime=&endTime=&taskType=' + 1, {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          //console.log(res, '外卖分析(年，月)')
          //订单金额
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            this.num1 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 2 +
          '&gameId=&startTime=&endTime=&taskType=' + 2, {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          //console.log(res, '外卖分析(年，月)')
          //订单金额
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            this.num2 = res.data.data.totalCount
            this.getEchartData()
          }
        })
        this.$axios.get('/help/selectHelpTakeList?page=' + 1 + '&limit=' + 10 + '&phone=&status=' + 2 +
          '&gameId=&startTime=&endTime=&taskType=' + 3, {
            headers: {
              'token': Authorization
            }

          }).then((res) => {
          //console.log(res, '外卖分析(年，月)')
          //订单金额
          if (res.data.code == 401) {
            this.$router.push("/");

          } else {
            this.num3 = res.data.data.totalCount
            this.getEchartData()
          }
        })
      },
      getDate() {
        var date = new Date();
        var year = date.getFullYear(); //  返回的是年份
        var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月
        if (month >= 1 && month <= 9) month = '0' + month;
        var dates = date.getDate(); //  返回的是几号
        var hours = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        this.time = year + "-" + month + "-" + dates + "+" + hours + ":" + min + ":" + sec
      },

    },
    mounted() {
      this.getEchartData()
      this.getselectOrdersToTal()
      this.Timer = setInterval(this.getselectOrdersToTal, 9000);
    },
    created() {
      this.getDate()
    },
    destroyed() {
      clearInterval(this.Timer);
    }
  }
</script>
<style scoped lang="less">
  .people {
    width: 100%;
    height: 100%;
  }
</style>